// Product image

.product-image {
	@include defaultMargin();
	.gallery {
		@include desktop-narrow {
			&,
			&.has-thumbs { padding: 380px 0 0; }
			.carousel:not(:first-child) { display: none; }
		}
		@include not-desktop {
			&,
			&.has-thumbs { padding: 380px 0 0; }
			.carousel:not(:first-child) { display: none; }
		}
	}
}

// Product badges

.product-badges {
	position: absolute; left: 0; top: 0; right: 0; z-index: 1; display: flex; justify-content: space-between;
	&__group {
		display: flex; flex-direction: column; align-items: flex-start;
		&:first-child:last-child { flex: 0 0 100%; align-items: flex-end; }
	}
	&__badge {
		&-square {
			margin: $gridunit-small/2 0 0; font-weight: $normal; padding: $gridunit-small/2 $gridunit-small; background: $brand-badge02-background; color: $brand-badge02-text; border-radius: 1em; font-size: $base-font-size;
			IMG { margin-right: $gridunit-small; max-width: 84px; min-width: 52px; height: auto; }
			&.theme01 { background: $brand-badge01-background; color: $brand-badge01-text; }
			&.theme02 { background: $brand-badge02-background; color: $brand-badge02-text; }
			&.theme03 { background: $brand-badge03-background; color: $brand-badge03-text; }
			&.theme04 { background: transparent; }
		}
		&-circle {
			margin: $gridunit-small/2 0 0; width: 4em; min-height: 4em; font-weight: $bold; font-size: $base-font-size; display: flex; align-items: center; justify-content: center; padding: $gridunit-small/2; border-radius: 3em;
			&.theme01 { background: $brand-badge03-background; color: $brand-badge03-text; }
			&.theme02 { background: $brand-badge04-background; color: $brand-badge04-text; }
		}
	}
}

// Product rating

.product-rating {
	white-space: nowrap; background: $brand-gray-200; border-radius: $base-radius; padding: $gridunit-small/2 $gridunit-small; color: $brand-accent-wcag; font-size: $base-font-size; font-weight: $normal; position: relative; top: -.4em;
	IMG { margin-right: $gridunit-small; }
}
// Product meta
.product-meta { }

// Product configurator

.product-configurator {
	display: flex; flex-wrap: wrap; margin: $gridunit-small $gridunit-small * -1 0;
	&__param {
		flex: 1; padding: 0 $gridunit-small; margin: $gridunit 0 0; min-width: 150px; box-sizing: border-box; display: flex; flex-direction: column;
		&.wide { flex: 0 0 100%; }
		&-title { display: block; font-weight: $medium-bold; flex: 0 1 100%; margin: 0; line-height: 1.2; }
		.form-control { margin: $gridunit-small/2 0 0; width: 100%; }
	}
	&__options {
		list-style: none; display: flex; flex-wrap: wrap; margin: 0;
		> LI {
			margin: $gridunit-small/2 $gridunit-small 0 0;
			&.selected LABEL SPAN { background-color: $brand-accent-200; border-color: $brand-accent-200; }
		}
		LABEL {
			position: relative; cursor: pointer; display: block;
			&:hover,
			&:active { SPAN { border-color: $brand-gray-placeholder; } }
			INPUT {
				position: absolute; left: -10000px;
				&:focus + SPAN { box-shadow: $shadow-focus; }
				&:checked + SPAN { background-color: $brand-accent-200; border-color: $brand-accent-200; }
			}
			SPAN { display: block; border: 1px solid $brand-gray-400; border-radius: $base-radius; padding: $gridunit-small $gridunit-small*1.5; }
		}

	}
	&__swatches {
		list-style: none; padding: 0; margin: $gridunit/2 $gridunit/-4 0; font-size: $base-font-size; display: flex; flex-wrap: wrap;
		@include mobile {
			justify-content: center;
		}
		LI {
			padding: 0; margin: $gridunit/4 $gridunit/4 0; flex: 0 0 80px;
			&.selected LABEL {
				INPUT[type="checkbox"],
				INPUT[type="radio"] {
					+ SPAN {
						color: $color02;
						SPAN.img { box-shadow: inset 0 0 0 2px $color02 }
					}
				}
			}
		}
		LABEL {
			display: block; position: relative; cursor: pointer; max-width: 80px;
			INPUT[type="checkbox"],
			INPUT[type="radio"] {
				position: absolute; left: -10000px; top: 0;
				&:checked + SPAN {
					color: $color02;
					SPAN.img { box-shadow: inset 0 0 0 2px $color02 }
				}
			}
			SPAN {
				display: block;
				&.img { padding-bottom: 100%; box-shadow: inset 0 0 0 1px $color-black; border-radius: $base-radius; background-position: 50% 50%; background-size: 80%; box-sizing: border-box; }
				&.text { word-wrap: break-word; font-size: $font-size-smaller; text-align: center; margin: $gridunit/4 0 0; }
			}
			&:hover {
				color: $brand-primary-800;
				SPAN.img { box-shadow: inset 0 0 0 2px $brand-primary-800 }
			}
		}
	}
}

// Product pricing

.product-pricing {
	display: flex; flex-wrap: wrap; margin: $gridunit 0 0; padding: $gridunit 0;
	.box-product & { margin: 0; padding: 0; }
	&__price {
		margin-right: $gridunit-small; text-align: left; color: $color-text-dark;
		P {
			margin: 0;
			&:first-child:last-child { padding-bottom: 1em; }
		}
		&.special { color: $brand-accent-wcag; }
		&.discount {
			.product-pricing__price-number {
				font-weight: $normal;
				&:before { content: ""; position: absolute; z-index: 1; top: 50%; left: -2%; width: 110%; height: 1px; background: $brand-gray-600; transform:rotate(-17deg); }
			}
			.product-pricing__price-currency { font-size: $font-size-smaller; font-weight: $extra-bold; }
		}
		&-perunit { font-size: $font-size-smaller; color: $brand-gray-placeholder; margin: $gridunit-small 0 0; }
		&-label {
			min-height: 14px; display: flex; align-items: center;
			IMG { width: 14px; height: 14px; }
		}
		&-label,
		&-prefix { margin: 0; font-size: $font-size-smaller; letter-spacing: 0.01em; font-weight: $bold; }
		&-value {
			display: flex; justify-content: flex-start; align-self: flex-end; align-items: flex-end; line-height: 1;
			IMG { width: 18px; height: 18px; }
		}
		&-number {
			font-weight: $medium-bold; font-size: $font-size-large; line-height: 1; display: flex; justify-content: flex-start; align-self: flex-start; position: relative;
			SPAN { font-size: $font-size-smaller; letter-spacing: 0.01em; font-weight: $extra-bold; }
		}
		&-currency { font-size: $font-size-larger02; }
		IMG { margin-right: $gridunit-small; }
	}
	&__meta { color: $color-text-medium; font-size: $font-size-smaller; font-style: italic; margin: 0; }
}

// Product actions

.product-actions {
	margin: $gridunit-small 0 0;
	@include desktop {
		display: flex;
		&__row { margin-right: $gridunit; }
	}
	.buttons.main {
		> LI { flex: 1; max-width: 350px; }
	}
	.button { width: 100%; justify-content: center; }
	&__row { display: flex; justify-content: space-between; flex-wrap: wrap; }
	&__quantity {
		display: flex; justify-content: flex-start; margin: $gridunit-small 0 0;
		BUTTON {
			cursor: pointer; position: relative; z-index: 1; background-color: $brand-gray-300; border: 1px solid $brand-gray-350; text-indent: -10000px; padding: $gridunit-small $gridunit; color: $color-text-dark; font-weight: $bold;
			&:first-child {
				border-radius: $base-radius 0 0 $base-radius;
				&:before { content: "-"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
			}
			&:last-child {
				border-radius: 0 $base-radius $base-radius 0;
				&:before { content: "+"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
			}
			&:hover { background-color: $brand-gray-350; }
			&:active { background-color: $brand-gray-350; border-color: $brand-gray-600; }
			&:focus { background-color: $brand-gray-350; box-shadow: $shadow-focus2; }
		}
		INPUT {
			border: 1px solid $brand-gray-350; margin: 0 -1px; flex: 0 0 auto; width: 4.5rem; padding: $gridunit-small $gridunit-small/2; text-align: center;
			&:focus { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px transparentize($brand-focus-1, .6); position: relative; z-index: 2; }
		}
	}
	&__discount {
		display: flex; justify-content: flex-start; margin: $gridunit-small 0 0;
		@include mobile { flex-wrap: wrap; }
		LABEL { padding: $gridunit-small+1; flex: 0 0 auto; font-weight: $medium-bold; }
		INPUT {
			border: 1px solid $brand-gray-350; border-radius: $base-radius; margin: 0; flex: 0 0 auto; width: 260px; padding: $gridunit-small $gridunit;
			&:focus { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px transparentize($brand-focus-1, .6); position: relative; z-index: 2; }
		}
	}
}

// Product campaign

.frame-ebeauty .product-campaign {
	background: $color-white; border: 2px solid $brand-gray-350; border-radius: $base-radius;
	&.theme02 { border-color: $brand-campaign-2; background: $brand-campaign-2-tint; }
	&.theme03 { border-color: $brand-campaign-4; background: $brand-campaign-4-tint;}
	&.theme04 { border-color: $brand-campaign-1; background: $brand-campaign-1-tint;}
	&.theme05 { border-color: $brand-campaign-5; background: $brand-campaign-5-tint;}
	&.theme06 { border-color: $brand-campaign-3; background: $brand-campaign-3-tint; }
	&__image {
		svg {
			path { fill: $color-text-dark; }
		}
	}
	&__inner {
		@include mobile {
			.button .button-inner .text { white-space: normal; }
		}
	}
	&__text {
		H2 { text-transform: uppercase; font-size: $base-font-size; font-weight: $bold; }
		P { margin: .5em 0 0; }
	}
	&__action {
		@include mobile {
			margin-top: $gridunit;
		}
		.button svg {
			path {
				fill: $brand-primary-800; stroke: $brand-primary-800;
			}
		}
	}
}

// Product parameters

.product-params {
	H2 { font-size: $base-font-size; font-weight: $bold; }
	DL {
		@include clearfix;
		font-size: $base-font-size; margin: $gridunit-small 0 0; line-height: 1.5;
		DT, DD { display: block; margin: 0;  }
		DD { font-weight: $bold;}
		@include not-mobile {
			DT { clear: both; float: left; width: 150px; margin: $gridunit-small 0 0; }
			DD { float: left; width: 100%; margin: $gridunit-small 0 0 150px*-1; padding-left: 150+$gridunit; box-sizing: border-box; font-weight: $bold; }
		}
		@include mobile {
			DT { margin: $gridunit-small 0 0; }
		}
	}
	P.has-icon {
		position: relative; padding-left: 24px;
		.ico { position: absolute; left: 0; top: 0; max-width: 18px; }
	}
	&.wide {
		DL {
			@include not-mobile {
				DT { width: 250px; }
				DD { margin: $gridunit-small 0 0 250px*-1; padding-left: 250+$gridunit; }
			}
		}
	}
}

// Product warning

.product-warning {
	position: relative; border-top: 1px solid $brand-gray-350; padding: $gridunit 0 0 50px; line-height: 1.4;
	&__icon { position: absolute; left: 0; top: $gridunit; }
	H2 { margin: 0; font-size: $base-font-size; font-weight: $bold; }
}

// Product availability

.product-availability {
	margin: 0; padding: 0;
	> LI {
		margin: $gridunit 0 0; padding: 0;
		&:first-child { margin: 0; }
	}
	H4, H5 {
		margin: 0; font-size: $base-font-size; font-weight: $normal; color: $brand-primary-800-wcag; text-transform: uppercase;
		+ P { margin: 0; }
	}
}

// Product rating

.product-reviews {
	H3 { font-size: $font-size-larger !important; margin: 0 !important; }
	&__item {
		margin: $gridunit 0 0; border-bottom: 1px solid $brand-gray-350; padding-bottom: $gridunit;
		H4 { margin: 0; }
	}
	&__score {
		display: flex; align-items: center; margin: $gridunit-small 0 0; text-indent: -10000px;
		SPAN { flex: 0 0 88px; height: 15px; overflow: hidden; margin-right: $gridunit-small; text-indent: 0; }
		IMG { position: relative; }
		&-1 IMG { top: -15px; }
		&-2 IMG { top: -15px*2; }
		&-3 IMG { top: -15px*3; }
		&-4 IMG { top: -15px*4; }
		&-5 IMG { top: -15px*5; }
	}
	&__comment { display: flex; margin: $gridunit-small 0 0; overflow: hidden; }
	&__author {
		flex: 0 0 130px; margin-right: $gridunit;
		P {
			margin: 0; font-weight: $bold;
			&.product-reviews__meta { font-weight: $normal; font-size: $font-size-small;}
		}
	}
	&__text {
		flex: 0 1 100%; line-height: 1.5; overflow: hidden;
		.plain-html { word-wrap: break-word; }
		P { margin: 0; }
	}
	&__form {
		margin: $gridunit 0 0;
		FIELDSET {
			margin: $gridunit 0 0; padding: 0; border: none;
			.radio-group { display: flex; flex-wrap: wrap; }
		}
		LEGEND { flex: 0 0 auto; margin-right: $gridunit; display: block; }
	}
}

// Product gallery

HTML .gallery {
	&-overlay {
		&__close {
			width: 90px; height: 90px; border-radius: 50%; background: $color-white; opacity: 1; box-shadow: 0px 0px 8px rgba(77, 77, 77, 0.4);
			svg {
				width: 30px; height: 30px;
				path { fill: $brand-primary-800-wcag; }
			}
			&:hover,
			&:active {
				svg path { fill: $brand-primary-900; }
			}
			&:focus { box-shadow: $shadow-focus; }
			@include not-desktop {
				width: 45px; height: 45px;
				svg { width: 20px; height: 20px; }
			}
		}
		&__content {
			.carousel {
				@include desktop {
					.control-dots { display: none; }
					&:not(:first-child) .control-arrow { display: none; }
				}
				@include not-desktop {
					&:not(:first-child) { display: none; }
					.control-dots .dot {
						opacity: 1; background: $color-white; border: 1px solid $brand-gray-placeholder; width: 12px; height: 12px; box-sizing: border-box;
						&.selected { background: $brand-primary-800; border-color: $brand-primary-800; }
						&:hover { background-color: $brand-gray-placeholder; border-color: $brand-gray-placeholder; }
						&:active { background-color: $brand-primary-900; border-color: $brand-primary-900; }
						&:focus { box-shadow: $shadow-focus2; }
					}
				}
				.control-arrow {
					background: $color-white; border: 1px solid $brand-primary-800;
					svg {
						display: none;
					}
					&:before { display: block; content: ""; width: 12px; height: 12px; border: 2px $brand-primary-800; border-style: solid solid none none; }
					&.control-prev:before { transform: rotate(225deg); margin-left: 6px; }
					&.control-next:before { transform: rotate(45deg); }
					&:hover {
						background-color: $brand-primary-800;
						&:before { border-color: $color-text-light; }
					}
					&:active {
						background-color: $brand-primary-900;
						&:before { border-color: $color-text-light; }
					}
					&:focus {
						box-shadow: $shadow-focus2;
					}
				}
				.slide { background-color: $color-white; text-align: center; }
				IMG { margin: 0 auto; max-height: 100%; max-width: 100%; height: auto; width: auto; object-fit: contain; object-position: center; }
			}
		}
	}
}

.carousel {
	.thumb {
		border-radius: $base-radius; border: 1px solid transparent;
		&.selected,
		&:hover { border: 1px solid $brand-gray-600; }
		&:focus { border: 1px solid $color-system-focus; }
	}
	&-root {
		&:focus { box-shadow: $shadow-focus; }
	}

}

// Products scroller

.products-carousel {
	&:hover {
		.carousel-inline.carousel .control-arrow { opacity: 1; }
	}
	.carousel-inline {
		&.size-auto .slide {
			@include desktop { flex: 0 0 33.3333%; }
			@include desktop-wide { flex: 0 0 25%; }
			@include tablet { flex: 0 0 50%; }
			@include mobile { flex: 0 0 100%; }
			.box-product { max-width: none; };
		}
		&.carousel .control-arrow {
			opacity: 0; transition: opacity $uispeed $uieasing;
			&:before { border-color: $brand-primary-800; }
			svg path { fill: $brand-primary-800; }
			@include mobile {
				opacity: 1;
			}
		}
	}
	@include mobile {
		margin: 0 $gridunit-small*-1;
	}
}

.product-filters {
	margin: $gridunit 0 0;
	&__form {
		display: flex; flex-direction: column; padding: $gridunit/2 0; border: 1px $color-black; border-style: none none solid none;
		&-row {
			display: flex; overflow: hidden;
			&.wrap {
				flex-wrap: wrap;
				.product-filters__form-item.secondary { opacity: 1; }
			}
			.ebeauty-dialog-content & { flex-wrap: wrap; flex-direction: column; margin: 0 $gridunit*-1; }
		}
		&-item {
			margin: $gridunit/2; display: flex;
			&.secondary {
				opacity: 0;
			}
			.toggler {
				border-radius: $base-radius;
				&:focus { background-color: $color-system-info-200; }
			}
			.checkboxes {
				max-height: 30vh; overflow: auto; padding-bottom: .5em; padding-left: 3px;
				&:not(:first-child) { margin-top: $gridunit-small; }
				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background: $brand-gray-200;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $brand-accent-100; border-radius: $base-radius;
				}
			}
			.ebeauty-dialog-content & {
				padding: 0 $gridunit 0 $gridunit-small; border-bottom: 1px solid $brand-gray-300; margin: 0;
				FIELDSET { flex: 1; }
			}
		}
		FIELDSET { border: none; padding: 0; margin: 0; }
		LEGEND {
			display: flex; position: relative; padding: $gridunit-small $gridunit-large $gridunit-small $gridunit-small; cursor: pointer; white-space: nowrap; flex-wrap: nowrap;
			&:hover { color: $brand-primary-800-wcag; }
			&:focus { box-shadow: $shadow-focus2; }
			.toggle-icon {
				position: absolute; right: 0; top: .7em;
				height: 7px; margin-left: $gridunit-small;
				IMG { display: block; }
			}
			.toggle-text { flex: 0 1 100%; }
			+ * { display: none; }
		}
		.buttons { margin: $gridunit-small/2 0 0 $gridunit-small; }
		@include mobile {
			padding: $gridunit/2 0;
			&-item { margin: $gridunit-small $gridunit-small $gridunit-small 0; }
			.buttons { flex: 0 0 100%; margin: 0; }
		}
	}
	&__applied {
		padding: $gridunit 0; display: flex; align-items: flex-start; justify-content: space-between; border-radius: 0 0 $base-radius $base-radius;
		P { margin: 0; font-weight: $normal; display: flex; flex-wrap: wrap; flex: 0 1 100%; align-items: baseline; }
		.button { background-color: $color-white; flex: 0 0 auto; }
		@include mobile {
			display: block; padding: $gridunit-small;
			&-title { flex: 0 0 100%; }
			.button { margin: $gridunit-small/2 0 0; }
			.ebeauty-dialog-content & {
				margin: 0 $gridunit*-1; border-top: none; padding: $gridunit-small $gridunit;
			}
		}
	}
	&__results {
		display: flex; justify-content: space-between; margin: $gridunit 0 0;
		&-count { margin: $gridunit-small 0 0; }
		FIELDSET { border: none; padding: 0; margin: 0; }
		LEGEND {
			display: block; padding: $gridunit-small; cursor: pointer;
			&:hover { color: $brand-primary-800-wcag; }
			&:focus { box-shadow: $shadow-focus2; }
			.toggle-icon {
				height: 7px; float: right; margin-left: $gridunit-small; margin-top: 6px;
				IMG { display: block; }
			}
		}
		@include mobile {
			flex-wrap: wrap; justify-content: space-between;/* background-color: lighten($brand-accent-100, 5%);*/ margin: 0 $gridunit*-1; padding: $gridunit;
			border-bottom: 1px solid $brand-gray-300;
			&-count { order: 3; flex: 0 0 100%; margin: $gridunit 0 0; }
			FIELDSET {
				order: 2; flex: 0 0 auto;
				.#{$ns}-popover-target {
					border: 1px solid $color-black; border-radius: $base-radius-large; background-color: $color-white;
				}
			}
			BUTTON { order: 1; flex: 0 0 auto; margin: 0; }

		}
	}
	&__tag {
		background: $brand-accent-100; padding: $gridunit-small/2 $gridunit-small; margin: $gridunit-small/2; border-radius: $base-radius; font-weight: normal;
		BUTTON {
			background: none; color: $color-text-dark; border-radius: 50%; text-indent: -10000px; border: none; width: 24px; height: 24px; position: relative; cursor: pointer;
			.icon {
				position: absolute; width: 16px; height: 16px; display: flex; overflow: hidden; left: 50%; top: 50%; transform: translate(-50%, -50%);
				IMG { width: 16px; height: 32px; }
			}
			&:focus { box-shadow: $shadow-focus; }
			&:hover .icon { align-items: flex-end; }
		}
	}
	& &__toggle-all {
		margin: 0 0 0 $gridunit-small;
		@include not-mobile { display: none; }
	}
	@include mobile {
		&__inner {
			display: none;
		}
	}
}

.product-filter-drop {
	position: relative; background-color: $color-white; box-shadow: $shadow04; padding: $gridunit; border-radius: $base-radius; z-index: 2; min-width: 300px; box-sizing: border-box;
}

.product-upsell {
	&__total {
		font-size: $font-size-larger; margin: $gridunit 0 0;
		P { margin: 0; }
		@include not-mobile {
			display: flex; justify-content: space-between; align-items: center;
			.button { margin: 0 $gridunit-small; }
		}
	}
}

.product-brand {
	font-size: 42px; line-height: 1;
	A { text-decoration: none; color: inherit; }
	@include mobile { font-size: $font-size-larger; }
}

TABLE.data01 SPAN.brand { text-transform: uppercase; font-weight: $medium; color: $brand-primary-800; font-size: $font-size-medium-large; }

