@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/popover/common";


.frame-ebeauty {
	flex: 1 0 auto; box-sizing: border-box; min-height: 100vh; min-height: -webkit-fill-available; line-height: $gridunit;
	display: flex; flex-direction: column;

	.#{$ns}-popover-target { display: block; position: relative; }

	.userhub {
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
		LI {
			margin-left: 20px; flex: 0 0 auto; position: relative;
			> UL { display: none; }
			&.delivery {
				font-size: $font-size-small;
				IMG { margin-right: $gridunit-small/2; }
				SPAN { display: flex; align-items: center; }
			}
		}
		.#{$ns}-popover-wrapper,
		.#{$ns}-popover-target { display: block; position: relative; }
		A {
			color: $color-text-header-primary; text-decoration: none; display: flex; align-items: center; justify-content: center; position: relative; padding: $gridunit-small; z-index: 0;
		}
		.icon {
			flex: 0 0 auto; display: block; width: $gridunit; height: $gridunit; overflow: hidden; margin: 0 $gridunit-small 0 0;
			IMG { width: 100%; height: auto; float: left; }
		}
		@include mobile {
			LI { margin: 0; }
			A { padding: 10px; }
		}
		@include not-mobile {
		}
	}

	.mini-cart {
		padding: $gridunit-small; border-radius: $base-radius;
		&.active {
			.icon {
				align-items: flex-end;
			}
		}
		&:hover { background: $color-background-crossportal-hover; }
		.visible-focus &:focus,
		&:active { background: $color-background-crossportal-active; }

		.icon {
			flex: 0 0 auto; display: flex; width: $gridunit; height: $gridunit; overflow: hidden; margin: 0 $gridunit-small 0 0;
			IMG { width: 100%; height: 96px; float: left; }
		}
		.products {
			position: absolute; left: 22px; top: 6px;
			background-color: $brand-primary-800; color: $color-white;
			font-size: $font-size-smaller; line-height: 1; padding: 3px; min-width: 12px; text-align: center; border-radius: 9px; box-sizing: content-box; box-shadow: 0 0 0 2px $color-white;
			display: flex; align-items: center; justify-content: center;
		}
		.amount {
			margin-left: 7px; color: $color-text-header-primary; font-weight: $bold;
			.currency { font-weight: $light; }
		}

		&:hover {
			color: $color-text-dark;
			.icon { opacity: 1; }
		}


		@include mobile {
			.icon { width: $gridunit-large; height: $gridunit-large; }
		}
	}

	&__languages {
		position: relative; width: 40px; height: 40px;
		.flag {
			flex: 0 0 auto; display: flex; align-items: center; justify-content: center; width: $gridunit-large; height: $gridunit-large; margin: 0;
			IMG { width: 100%; height: 100%; margin: auto 0;  display: block; }
		}
		.label { @include visuallyHidden; }
		.languages-toggle { border-radius: $base-radius; }
		.languages-toggle, .languages-drop A {
			padding: $gridunit-small; text-decoration: none; position: relative; display: block;
			&:hover { background: $color-background-languages-hover; }
			&:active,
			.visible-focus &:focus { background: $color-background-languages-active; outline: none; }
		}
		.languages-drop {
			background: $color-background-languages-idle; box-shadow: $shadow-menus; border-radius: $base-radius; overflow: hidden;
			LI { margin: 0; }
		}
		> UL { display: none; }
	}

	&__pre-header {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; box-sizing: border-box; background-color: $color-background-header-primary; color: $color-text-header-primary;
		padding: $gridunit-small/2 0;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: 0 $spacer-desktop;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__links {
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; font-size: $font-size-small;  color: $color-text-header-secondary;
			LI {
				margin-left: $grid-gap; color: $color-text-header-secondary; flex: 0 0 auto; position: relative; display: flex; align-items: center; justify-content: center;
				&.highlighted { color: $color-text-header-highlighted; font-weight: $bold; }
				&:first-child { margin-left: 0; }
			}
			A {
				color: inherit; text-decoration: none; position: relative; padding: 5px 2px; z-index: 0;  display: flex; align-items: center; justify-content: center; border-bottom: 1px solid transparent;
				&:hover, &:active { border-bottom-color: $brand-primary-800; }
				.visible-focus &:focus { box-shadow: $shadow-focus; }
			}
			.icon {
				flex: 0 0 auto; display: block; width: $gridunit; height: $gridunit; overflow: hidden; margin: 0 $gridunit-small 0 0;
				IMG { width: 100%; height: auto; float: left; }
				svg path { fill: $color-text-header-highlighted; }
			}
			@include mobile {
				display: none;
			}
		}

		&__contacts {
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;
			LI {
				margin-left: $grid-gap; color: $color-text-header-secondary; flex: 0 0 auto; position: relative; display: flex; align-items: center; justify-content: center;
				&.highlighted { color: $color-text-header-highlighted; font-weight: $bold; }
			}
			A {
				color: inherit; text-decoration: none; position: relative; padding: 5px 0; z-index: 0;  display: flex; align-items: center; justify-content: center;
				&:hover {
					color: $color-text-dark;
					.icon { opacity: 1; }
				}
			}
			.icon {
				flex: 0 0 auto; display: block; width: $gridunit; height: $gridunit; overflow: hidden; margin: 0 $gridunit-small 0 0;
				IMG { width: 100%; height: auto; float: left; }
				svg path { fill: $color-text-header-highlighted; }
			}
		}

		@include not-desktop {
			&__limiter { padding: $gridunit-small $spacer-tablet; }
		}

		@include mobile {
			&__limiter { padding: 0 $spacer-mobile; justify-content: flex-end; }
			&__contacts {
				margin: 0; min-height: 5px;
				> LI { margin-top: $spacer-mobile; margin-bottom: $spacer-mobile; }
			}
		}
	}

	&__header {
		display: flex; flex-direction: column; flex: 0 0 auto; box-sizing: border-box; color: $color-text-header-primary;

		@include not-desktop {
			HTML:not(.is-sticky) & { padding-bottom: 0 !important; }
		}

		A { color: inherit; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: 0 $spacer-desktop;
			@include mobile { display: flex; flex-direction: row; align-items: stretch; justify-content: space-between; padding: 0 $spacer-mobile; }
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between; }
		}

		&__nav { background-color: $color-background-header-secondary; }
		&__primary {
			background-color: $color-background-header-primary; border-bottom: 1px solid $color-border-header;
			@include desktop { margin-top: -1px; }
		}
		&__nav {
			transition: opacity $uispeed $uieasing;
			.frame-ebeauty__header__limiter { padding: 0 $gridunit-small; }
		}

		.menu-toggle {
			position: absolute; left: $gridunit; display: none !important; padding: $gridunit $gridunit-small; border-radius: $base-radius; border: none; background: none; color: $color-text-header-primary; align-items: center; cursor: pointer;
			IMG { margin-right: $gridunit-small; }
			&:hover { background: $color-background-crossportal-hover; text-decoration: none; }
			.visible-focus &:focus,
			&:active { background: $color-background-crossportal-active; }
		}

		.logo {
			padding: $gridunit-small 0 $gridunit-small; display: flex; align-items: center; justify-content: center; transition: opacity $uispeed $uieasing;
			img { width: auto; height: $logo-height; display: block; }

			@include not-desktop { padding: 0; margin-right: auto; }
			@include mobile {
				img { width: auto; max-width: 240px; height: 34px; }
			}
		}

		.userhub {
			flex: 0 0 auto;
			.link-search { display: none; }
			.link-account {
				A.toggler {
					padding: $gridunit-small/2 $gridunit-small; border-radius: $base-radius; position: relative; min-width: 130px; justify-content: flex-start;
					.label {
						display: block;
						SPAN {
							display: block;
							&.account {
								&-title { font-size: $font-size-smaller; font-weight: $bold; letter-spacing: 0.01em; }
								&-subtitle { text-transform: uppercase; letter-spacing: 0.001em; line-height: 0.875; }
							}
						}
					}
					&:hover {
						background: $color-background-crossportal-hover;
					}
					&:active,
					.visible-focus &:focus {
						background: $color-background-crossportal-active;
					}
					.icon { width: 18px; height: 18px; overflow: hidden; }
				}
				.#{$ns} {
					&-popover-open > A {
						background: $color-white; color: $color-text-dark; border-radius: $base-radius $base-radius 0 0;
						&:before { border-style: solid solid none solid !important; border-radius: $base-radius $base-radius 0 0 !important; }
						&:after { display: none; }
						&:hover,
						.visible-focus &:focus,
						&:active { background: $color-white; }
					}
					&-transition-container {
						width: 100%;
						UL {
							background: $color-white; margin-top: $gridunit-small; border-radius: 0; padding: $gridunit-small 0 $gridunit-large 0; position: relative;
							&:before {
								content: "";
								position: absolute; top: 0; left: -2px; right: -2px; bottom: -2px; border: 1px $color-black; border-style: solid; border-radius: 0;
							}
						}
						LI { margin-left: 0; }
						A {
							border: none; padding: $gridunit-small $gridunit; text-align: left; display: block; text-transform: none; font-weight: $normal; color: $color-text-dark;
							&:hover { background: $color-background-crossportal-hover; }
							&:active,
							.visible-focus &:focus { background: $color-background-crossportal-active; }
							&.button { margin: $gridunit $gridunit 0; border-radius: $base-radius; text-align: center; white-space: normal; background: $brand-gray-300; font-size: $base-font-size; }
						}
					}
				}
			}
			.label { display: none; }
			A {
				.icon { float: left; width: $gridunit-large; height: $gridunit-large; }
			}
		}

		@include desktop {
			position: sticky; z-index: 10; top: 0; padding-bottom: 0 !important;
			.frame-ebeauty__languages { order: 2; margin-top: $gridunit/2; }
			&__limiter { position: relative; }
			.header {
				&__inner {
					&.is-sticky {
						//.frame-ebeauty__header__nav { position: absolute; top: 100%; left: 0; right: 0; }
						+ .#{$ns}-portal { transition: opacity $uispeed $uieasing; }
					}
				}
			}
		}

		@include not-desktop {
			position: relative; z-index: 10; top: 0 !important;
			.is-sticky { position: fixed; top: 0; left: 0; right: 0; }
			&__primary {
				max-height: 100px; /*transition: all $uispeed $uieasing;*/
				.is-sticky & { max-height: 0; overflow: hidden; }
				.frame-ebeauty__header__limiter { padding: $spacer-tablet/2 $spacer-tablet; }
			}
			&__nav {
				border-bottom: 2px solid $color-background-crossportal-active; background: $color-background-header-secondary;
				.is-mobile-sticky & {
					/*position: fixed; left: 0; top: 0; right: 0;	z-index: 2;*/
					//animation: fadein $uieasing $uispeed;
				}
				.toggler {
					font-size: $font-size-small; text-align: center; letter-spacing: -0.05em; padding: $gridunit-small; box-sizing: border-box; flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;
					.icon {
						margin: $gridunit-small; height: $gridunit-large; width: $gridunit-large; overflow: hidden;
						img { width: 100%; height: 100%; }
					}
				}
				.frame-ebeauty__header__limiter { padding: 0; align-items: flex-end; }
			}
			.frame-ebeauty__languages { order: 2; }
			.logo { order: 1; margin-right: $gridunit; }
			.userhub { display: none; }
		}
		@include mobile {
			&__primary .frame-ebeauty__header__limiter { padding: 0 $spacer-mobile $spacer-mobile; }
			.search-desktop { display: none; }
		}
	}

	&__breadcrumb {
		padding: $gridunit 0; font-size: $font-size-medium-small; font-weight: $normal;
		A {
			margin-right: $gridunit-small; color: $color-text-dark; text-decoration: none;
			&:hover,
			&:active { color: $brand-primary-800-wcag; text-decoration: none; }
			&:focus { box-shadow: $shadow-focus; }
			&:after { content: "\2192"; margin: 0 $gridunit-small; }
			@include mobile {
				display: none;
				&:nth-last-child(2) {
					display: block;
					&:after { display: none; }
					&:before { content: "←"; margin-right: $gridunit-small; }
				}
			}
		}
		SPAN {
			color: $brand-gray-placeholder;
			@include mobile { display: none; }
		}

	}

	&__body {
		flex: 1 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column;
		//overflow: hidden; // Todo: For position sticky to work
		&__limiter { width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; flex: 0 1 auto; padding: 0 $gridunit; box-sizing: border-box; }

		&.gray { background-color: $color-gray-lightest; }
		@include mobile {
			overflow: hidden;
		}
	}

	&__section {
		margin: 0;
		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; padding: $gridunit; box-sizing: border-box;
			.content { padding-bottom: $gridunit-large*2; }
			@include mobile {
				.frame-ebeauty__body__limiter & { padding: $gridunit 0; }
			}
		}
		&__header {
			padding: $gridunit*2 0;
			H2 {
				margin: 0;
				A {
					text-decoration: none !important; margin-left: $gridunit; color: $color-text-dark; display: inline-flex; font-size: $font-size-medium; line-height: 1.5; align-items: center;
					SPAN { display: block; border-bottom: 1px solid $color-text-dark; margin-right: $gridunit-small; }
					&:after { content: ""; width: 14px; height: 14px; float:right; background: url(img/icon_arrow_right.svg); margin-left: $gridunit-small; }
					&:hover { color: $brand-primary-800-wcag; text-decoration: none; }
				}
			}
		}
		&.theme01 { background-color: $brand-campaign-1; }
		&.theme02 { background-color: $brand-campaign-2; }
		&.theme03 { background-color: $brand-campaign-3; }
		&.theme04 { background-color: $brand-campaign-4; }
		&.theme05 { background-color: $brand-campaign-5; }
		&.theme06 { background-color: transparentize($brand-primary-800,0.95s); }
	}

	&__footer {
		color: $brand-gray-placeholder; line-height: 1.5; margin: $gridunit-large 0 0;
		H1, H2, H3, H4, H5, H6 { color: inherit; letter-spacing: 0.15em; font-weight: $bold; margin: $gridunit-small 0 0; font-size: $base-font-size; text-transform: uppercase; }
		&__primary,
		&__secondary { background-color: $brand-gray-200; padding: $gridunit-large 0; }
		A { color: inherit; }

		.profile { width: 112px; height: auto; align-self: flex-start; margin-right: $gridunit; }
		.column {
			overflow: hidden; position: relative;
			&.contact { overflow: visible; }
		}
		.group {
			&:not(:first-child) { margin: $gridunit*2 0 0; }
		}
		&__form {
			@include mobile { margin-top: $gridunit*2; }
			*:focus { box-shadow: $shadow-focus2 !important; }
		}
		.form-list {
			margin: $gridunit -3px 0;
			> LI {
				padding: 0;
				&.error { color: $color-system-danger-300; }
			}
			TEXTAREA { height: 5em; }
		}
		.cert {
			margin: $gridunit-small 0 0;
			IMG { height: 112px; width: auto; }
			A { display: inline-block; margin-right: $gridunit-large; }
		}

		@include mobile {
			.column {
				&.about { padding-bottom: $gridunit; flex-direction: column; display: flex; justify-content: space-between}
			}
		}
		@include not-mobile {
			.column {
				flex: 1; padding-right: $gridunit; box-sizing: border-box;
			}
		}
		@include desktop {
			.column {
				max-width: 33.333%;
				&.wide {
					flex-grow: 2; max-width: 66.666%; display: flex;
					> DIV { flex: 1; padding-right: $gridunit; }
				}
			}
		}
		@include tablet {
			.column { flex: 1 0 50%; }
			&__form {
				.form-wrapper {
					display: flex; margin: 0 $gridunit-small*-1;
					&__column { flex: 1; padding: 0 $gridunit-small; }
				}
			}
			&__limiter { flex-wrap: wrap !important; }
		}

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; padding: 0 $gridunit; box-sizing: border-box;
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: stretch; }
		}


		UL.nav {
			padding: 0; margin: $gridunit-small 0 0 0;
			> LI { margin: 0; }
			A {
				text-decoration: none;
				&:hover { text-decoration: underline; }
			}
		}

		&__social {
			display: flex; flex-direction: row; flex-wrap: wrap;
			> LI {
				margin: $gridunit $gridunit 0 0;
				A {
					display: flex; align-items: center; justify-content: center; width: 36px; height: 36px;
					&:hover IMG { opacity: 0.8 }
				}
			}
			IMG { width: 36px; height: 36px; opacity: 0.4; }
		}

	}

	&__pre-cart {
		flex: 0 0 auto; @include defaultPadding; box-sizing: border-box; background-color: $color-white;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto;
			@include mobile { text-align: center; }
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
		}

		&__image {
			position: relative; @include defaultMargin();
			@include not-mobile { margin-right: $gridunit; flex: 0 0 110px; }
			DIV {
				padding: 66% 0 0 0; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain;
				@include mobile { height: 100px; padding: 0; }
			}
		}

		&__product {
			flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; @include defaultMargin();
		}

		&__title {
			font-weight: $bold; font-size: $font-size-medium;
		}

		&__message {
			color: $color-success;
		}

		&__actions {
			@include defaultMargin();
			@include not-mobile { flex: 0 0 150px; margin-left: $gridunit; }
			.cart-buttons {
				margin: 0;
				> LI:first-child { margin-top: 0; }
			}
		}
	}

	&__copyright {
		display: flex; flex-direction: row; flex-wrap: nowrap; flex: 0 0 auto; @include defaultPadding; box-sizing: border-box;
		background-color: $color-gray-lighter; color: $color-gray-medium; font-size: 12px;

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; border-top: 1px solid rgba($color-black, 0.1);
			@include not-mobile { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; }
		}
		.column { flex: 0 1 auto; margin-right: $gridunit; }
	}

	&__illustration { background: 50% 50% no-repeat; background-size: cover; background-color: $color-gray-lightest; padding-top: 40%; margin-top: 50px; }

	&__page-header {
		flex: 0 0 auto; position: relative; z-index: 0; display: flex; flex-direction: column;
		&__limiter { width: 100%; max-width: $content-maxwidth; box-sizing: border-box; margin-left: auto; margin-right: auto; flex: 0 1 auto; padding: 0 $gridunit; }

		&-actions {
			@include not-mobile {
				display: flex; justify-content: space-between; flex-wrap: wrap; align-items: baseline;
				NAV {
					UL { display: flex; flex-wrap: wrap; }
					LI {
						margin: 0 0 0 $gridunit;
						&.separated {
							margin: 0 0 0 $gridunit*2; border-left: 1px solid $brand-accent-2-200; padding-left: $gridunit*2; font-size: 80%;
							A { text-decoration: none; }
						}
					}
				}
			}
			@include mobile {
				NAV LI {
					&.separated {
						margin-top: $gridunit*2; border-top: 1px solid $brand-gray-200; padding-top: $gridunit-small;
					}
				}
			}
			NAV {
				LI {
					padding: 0;
					&:before { display: none; }
					&.active A { color: $color-text-dark; text-decoration: none; pointer-events: none; }
					&.separated {
						font-size: 80%;
						A { text-decoration: none; }
					}
				}
			}
		}
		H1 {
			margin: .5em 0 0;
			.tag {
				@include not-mobile {
					position: relative; top: -0.5em;
				}
			}
			&.has-background {
				background-position: center; background-repeat: no-repeat; background-size: cover; text-align: center; padding: $gridunit-large $gridunit $gridunit-large;
				@include mobile {
					margin-left: -$gridunit; margin-right: -$gridunit;
				}
			}
		}

		.description { margin: 0; }
		+ .frame-ebeauty__body {
			SECTION.frame-ebeauty__section:first-child { margin: $gridunit*2 0 0; }
		}
	}

	&__product-header {
		display: flex; flex-direction: column; flex: 0 0 auto; box-sizing: border-box; position: sticky; z-index: 8; top: 96px;
		padding: 0 $spacer-desktop; backdrop-filter: saturate(180%) blur(20px);
		background-color: rgba($color-white, 0.72);
		&.dark { background-color: rgba(29, 29, 31, 0.72); color: $color-white; border-bottom: 1px solid rgba($color-white, 0.1); }

		@include mobile { top: 50px; padding: 0 $spacer-mobile; }
		@include tablet { top: 76px; padding: 0 $spacer-tablet; }

		&__limiter {
			width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; position: relative; padding: 15px 0;
			display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-between;
		}

		&__button {
			cursor: pointer; display: block; text-align: center; white-space: nowrap; margin-left: $gridunit; flex: 0 0 auto;
			background: $color-link; color: $color-white; font-size: 12px;
			font-weight: 400; padding: 0 11px; border-radius: 12px; line-height: 24px;
			&:hover { background-color: $color-link-hover; text-decoration: none; }

			&[disabled],
			&.disabled { opacity: 0.5; pointer-events: none; }
		}

		H1 { font-size: 20px; margin: 0; }
	}

	&__cookiebar {
		position: sticky; bottom: 0; background-color: $brand-accent-100; text-align: center;
		&__limiter {
			max-width: $content-maxwidth; margin: 0 auto; width: 100%; padding: $gridunit; box-sizing: border-box;
			display: flex; justify-content: center; align-items: center; flex-wrap: wrap;
			.buttons { margin: $gridunit-small !important; }
		}
	}

	@include mobile {
		&__pre-header .search { display: none; }
	}
	.menu-item .inner-text { max-width: 100%; }
}

@include not-mobile {
	#giftNotification { position: relative; margin-top: -1px; }
}

.breadcrumb {
	margin: $gridunit 0 0; font-size: $font-size-medium-small; font-weight: $medium; line-height: 1.4; display: flex; flex-direction: row; justify-content: space-between; color: $color-text-dark; white-space: nowrap;
	.back { margin: 5px 0 0 0; }
	.path {
		display: flex; flex-direction: row; flex-wrap: wrap;
		> LI {
			margin: 5px 10px 0 0; font-weight: $medium-bold;
			&:not(:last-child) {
				A { margin-right: 10px; }
				&:after { content: "\2192"; }
			}
			&:last-child {
				A { color: $brand-gray-placeholder; }
			}
		}
	}
	A {
		color: inherit; text-decoration: none;
		&:hover { color: $color-link-hover; }
	}
	@include mobile {
		.path {
			padding: $gridunit-large 0;
			> LI {
				display: none;
				&:nth-last-child(2) {
					display: block;
					&:after { display: none; }
					&:before { content: "←"; margin-right: $gridunit-small; }
				}
			}
		}
	}
}

.global-frame-ebeauty {
	padding: 0; margin: 0; background-color: $color-white;
	BODY { padding: 0; margin: 0; }
}

// Overlay placement

.global-frame-ebeauty {
	@include not-desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { top: 0; }
	}
	@include desktop {
		.#{$ns}-overlay .overlayholder,
		.#{$ns}-overlay .overlayholder-backdrop { left: 0; }
		.#{$ns}-overlay .overlay-focusview-holder { padding-left: 0; }
	}
}

// Searchfield

.search {
	box-shadow: none; flex: 0 1 250px; display: block; position: relative;
	LABEL {
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; flex: 1 1 auto; position: relative; overflow: hidden; background: $color-white; border-radius: $base-radius-large;
	}
	.label { display: none; }
	INPUT:not([type="submit"]) {
		padding: $gridunit-small $gridunit; border: 0 none; outline: none; box-shadow: none; width: 100%; overflow: hidden; border-radius: 0; flex: 1 1 auto; box-sizing: border-box; background-color: transparent;
		color: $color-black; height: auto; line-height: 1.5; font-size: $base-font-size;
		&::placeholder { color: $color-text-medium; }
	}
	BUTTON,
	INPUT[type="submit"] { background: none; border: none; cursor: pointer; }

	.icon {
		flex: 0 0 auto; display: flex; padding: $gridunit-small; height: 18px; box-sizing: content-box; justify-content: center; align-items: flex-start; overflow: hidden; margin: 0;
		&:hover { align-items: flex-end; }
		IMG { width: 18px; height: auto; float: left; }
		@include mobile { margin: 0; }
	}
	&__clear {
		display: flex; justify-content: center; align-items: center;
		svg {
			width: 14px; height: 14px;
			path { fill: $color-system-danger-800; }
		}
	}

	&__inner {
		display: flex; flex-direction: column; justify-content: center; color: $color-text-dark; position: relative;
		border-radius: 0; transition: box-shadow $uispeed $uieasing;
		&:before { content: ""; pointer-events: none; position: absolute; z-index: 2; left: 0; right: 0; top: 0; bottom: 0; border: 1px $color-black; border-style: none none solid none; border-radius: 0; }
		&:hover,
		&.active,
		&.open {
			&:before {
				border-color: $brand-primary-600;
			}
			BUTTON.icon  { align-items: flex-end; }
		}
		&.open {
			&:before {
				border-radius: $base-radius-large $base-radius-large 0 0; border-style: solid solid none solid;
				@include mobile { bottom: 20px; }
			}
			LABEL { border-radius: $base-radius-large $base-radius-large 0 0; }
		}
	}

	&__results {
		&__list {
			flex: 0 1 100%; position: relative;
			padding: 0 $gridunit-small; transition: all $uispeed $uieasing 0ms;
			@include desktop {
				transform: translate(0, -20px); @include overflow();
			}
		}
		&__separator { border-bottom: 1px solid $color-gray-lighter; }
		&__actions { border-top: 2px solid $color-gray-lighter; text-align: center; padding: $gridunit; }
		&__result {
			font-size: $font-size-small;
			A {
				display: flex; color: inherit; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: flex-start; text-decoration: none; overflow: hidden; white-space: nowrap; padding: $gridunit-small $gridunit; position: relative; line-height: 18px;
				&.active,
				&:hover {
					text-decoration: none;
					.name { color: $color-link; }
				}
				&.active { background-color: rgba($color-link, 0.05); }
				SMALL { font-size: $font-size-smallest; }
				SPAN.secondary {
					color: $color-text-medium;
					&:not(:last-child):after { content: "\2192"; padding: 0 $gridunit-small; }
				}
			}
			.image { flex: 0 0 40px; width: 40px; height: 40px; margin-right: 20px; overflow: hidden; background-size: contain; background-repeat: no-repeat; background-position: 50% 50%; }
			.text {
				flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis;
				display: flex; flex-direction: row; flex-wrap: nowrap;
			}
			.name { font-weight: $medium; flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
			.addon { text-align: right; color: $color-gray-medium; margin-left: 10px; }
			.price {
				color: $color-text-dark;
				STRONG { color: $brand-accent-wcag; font-weight: $normal; }
				DEL {
					text-decoration: none; position: relative; margin-right: $gridunit-small;
					&:before { content: ""; border-top: 1px solid $color-gray-medium; position: absolute; left: 0; top: 50%; right: 0; }
				}
			}

		}
	}

	@include mobile {
		flex: 0 1 auto;
	}
}

.search-desktop {
	flex: 0 1 550px; position: relative; margin-left: $gridunit-large;
	@include not-desktop { display: none; }

	&__content {
		flex: 1 0 auto; display: flex; flex-direction: column;
		FORM > LABEL {
			position: relative;
			&:before {
				content: ""; pointer-events: none;
				position: absolute; z-index: 2; left: 0; right: 0; top: 0; bottom: 0;
				border: 2px solid $brand-primary-800; border-radius: 19px;
			}
		}
		.search {
			flex: 1 0 auto;
			BUTTON.icon {
				&.search__close { display: none; }
			}
		}
		&.open {
			pointer-events: auto; opacity: 1;
			.search { transform: none; }
		}
	}
	&__backdrop {
		position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 2; background-color: rgba($color-gray-darker, 0.48); transition: all $uispeed $uieasing 0ms; opacity: 0; pointer-events: none;
		&.open { pointer-events: auto; opacity: 1; }
	}

	.search__results {
		position: absolute; left: 0; right: 0; top: 100%; z-index: 100; padding: 0 4px 4px; pointer-events: none;
		background-color: $color-white; color: $color-text-dark; border-radius: 0 0 $base-radius-large $base-radius-large; transition: all $uispeed $uieasing 0ms; opacity: 0;
		box-shadow: 0 1px 10px 0 #0000001F, 0 4px 5px 0 #00000024;
		max-height: 350px; overflow: hidden; display: flex; flex-direction: column;

		&.open {
			opacity: 1; pointer-events: all;
			&:before { pointer-events: none; z-index: 2; content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 1px $color-header-accent; border-style: none solid solid; border-radius: 0 0 $base-radius-large $base-radius-large; }
			& .search__results__list { transform: translate(0, 0); }
		}
	}
}

// Mobile search

.search-mobile {
	flex: 0 0 25%;
	@include desktop { display: none; }
	&-content {
		padding: $gridunit; flex: 0 0 100%; box-sizing: border-box;
		.search__results {
			padding: 0 4px 4px; position: relative;
			background-color: $color-white; color: $color-text-dark; border-radius: 0 0 $base-radius-large $base-radius-large; transition: all $uispeed $uieasing 0ms; opacity: 0;
			overflow: hidden; display: flex; flex-direction: column;

			&.open {
				opacity: 1;
				&:before { pointer-events: none; z-index: 2; content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 4px $color-header-accent; border-style: none solid solid; border-radius: 0 0 $base-radius-large $base-radius-large; }
				& .search__results__list { transform: translate(0, 0); }
			}
		}
	}
}
.userhub-mobile {
	flex: 0 0 25%;
	UL { display: none; }
	.account-title { display: none; }
	@include desktop { display: none; }
}
.cart-mobile {
	flex: 0 0 25%; display: flex;
	.mini-cart {
		font-size: $font-size-small; letter-spacing: -0.05em; position: relative; padding: $gridunit-small; box-sizing: border-box; flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;
		.icon { width: $gridunit-large; height: $gridunit-large; margin: $gridunit-small; }
		.products { left: 50%; top: $gridunit; margin-left: 2px; }
	}
	.amount { display: none; }
	@include desktop { display: none; }
}

.overlay-search {
	.overlay-focusview-scroller { height: auto; flex: 0 1 100%; }
	.overlay-focusview-content { display: flex; flex-direction: column; }
	.layout-overlay-content { display: flex; }
	&-content {
		background-color: $color-white;
	}
}

// Category tree

.category-tree {
	flex: 0 0 auto; margin-top: $gridunit;

	.menutoggler {
		display: none; border-bottom: 1px solid $color-gray-lighter; margin: $gridunit * -1 $gridunit * -1 0 $gridunit * -1; padding: $gridunit / 2 $gridunit; color: inherit;
		@include mobile { display: flex; align-items: center; justify-content: flex-start; }

		.icon {
			flex: 0 0 auto; display: block; width: 12px; height: 12px; overflow: hidden; margin: 0 7px 0 0; opacity: 0.3;
			IMG { width: 100%; height: auto; float: left; transform: translate(0, -50%); }
		}
		.label {
			flex: 1 1 auto;
		}
	}

	&__group {
		overflow: hidden; position: relative; border-bottom: 1px solid rgba($color-black, 0.05); user-select: none; line-height: 20px;
		@include mobile { display: none; }

		INPUT[type="checkbox"] { display: none; }

		LABEL {
			display: block; background-color: $color-white; z-index: 2; cursor: pointer; position: relative; font-weight: $bold; box-sizing: border-box;
			margin: 0; padding: $gridunit / 2 0;
			&:before { content: "+"; float: right; transition: all $uispeed $uieasing 0ms; font-weight: 400; display: block; width: 30px; text-align: center; line-height: 20px; }
			&:hover { color: $color-link; }
		}

		UL { display: none; }

		INPUT[type="checkbox"] + * + * { transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; width: 100%; z-index: -1; margin: 0; transition: all $uispeed $uieasing 0ms; display: block; }
		INPUT[type="checkbox"]:checked ~ LABEL:before,
		INPUT[type="checkbox"]:checked + * LABEL:before { transform: rotate(45deg); }
		INPUT[type="checkbox"]:checked + LABEL ~ *,
		INPUT[type="checkbox"]:checked + * ~ * { display: block; transform: none; pointer-events: auto; position: static; z-index: 0; }

		A {
			display: block; color: inherit; padding: $gridunit / 4 0;
			&:hover { text-decoration: none; color: $color-link; }
			&.active {
				color: $color-link;
				+ UL { display: block; }
			}
			.count { opacity: 0.3; }
		}

		.item-row {
			display: flex; flex-direction: row; flex-wrap: nowrap; background-color: $color-white; position: relative; z-index: 1;
			A { flex: 1 1 auto; padding: $gridunit / 4 0; display: block; }
			LABEL {
				flex: 0 0 auto; padding: $gridunit / 4 0;
				width: 30px; text-align: center;
			}
		}

		UL:not([class]) {
			padding: $gridunit / 4 0 $gridunit / 2 $gridunit / 2; margin: 0; box-sizing: border-box; overflow: hidden;
			LI {
				padding: 0; margin: 0; overflow: hidden;
				&:before { display: none; }
				LABEL { font-weight: 400; }
			}

			LI A { padding: $gridunit / 4 0; }
			LI LI {
				font-size: $font-size-small;
				A, LABEL { padding: 2px 0; }
				LABEL:before { opacity: 0.5; }
				UL { padding: $gridunit / 2 0 $gridunit / 2 $gridunit / 2; }
			}
			LI LI LI { font-size: $font-size-smaller; }
		}
	}

	&__mobile {
		& &__heading {
			&.right { flex-direction: row-reverse; }
			background-color: $color-white; color: $color-text-dark; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
			BUTTON {
				cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
				svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
				path { fill: currentColor; }
			}
			.logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
		}
		& &__content {
			padding: 0 20px 20px 20px;
		}
	}
}

.overlay-category-tree {
	&-content {
		background-color: $color-white; color: $color-text-dark;
		.category-tree {
			.menutoggler { display: none; }
			&__group { display: block; }
		}
	}
}

.user-menu {
	&-items {
		LI {
			padding: 0;
			&:first-child > A { margin-top: $gridunit-small; }
			> A {
				padding: $gridunit-small*1.5 $gridunit; color: $color-text-dark; text-transform: none; text-decoration: none; font-weight: $medium-bold; letter-spacing: -0.01em;
				display: flex; justify-content: space-between; align-items: center;
				&.button { display: inline-block; margin: $gridunit $gridunit 0; border-radius: $base-radius; text-align: center; white-space: normal; background: $brand-gray-300; font-size: $base-font-size; }
			}
		}
	}
}

#reserveTimer { position: sticky; left: 0; right: 0; top: 0; z-index: 20; }

@keyframes fadein {
	from { top: -90px; opacity: 0; }
	to   { top: 0; opacity: 1; }
}
