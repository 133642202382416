.ebeauty-dialog {
  &-heading {
    position: relative;
    BUTTON {
      cursor: pointer; border-radius: 50%; border: none; width: 55px; height: 55px; display: flex; justify-content: center; align-items: center;
      position: absolute; right: -$gridunit*2; top: -$gridunit*2;
      background: $color-white; box-shadow: 0 0 8px rgba(77, 77, 77, 0.4);
      svg path { fill: $brand-primary-800-wcag; }
      @include mobile {
        width: 42px; height: 42px; right: -$gridunit-large; top: -$gridunit-large;
      }
      .ebeauty-dialog-fullsize & { right: -$gridunit-small !important; top: -$gridunit-small  !important; }
      .ebeauty-dialog-minicart & {
        right: 0; top: -$gridunit-small; background-color: transparent; box-shadow: none;
      }
    }
    H2 { margin: 0; font-weight: $bold; }

  }
  .overlay-dialog {
    &-holder {
      padding: $gridunit-large;
      @include mobile {
        padding: $gridunit;
      }
    }
    &-content {
      max-width: 680px; padding: $gridunit; text-align: left;
      IMG { max-width: 100%; }

    }
  }
  &.wide .overlay-dialog-content { max-width: 1200px; }
  &-fullsize {
    .overlay-dialog-content { padding: 0; }
  }
  &-minicart {
    .ebeauty-dialog-heading { padding: $gridunit $gridunit $gridunit-small; border-bottom: 1px solid $brand-gray-300; }
    @include not-mobile {
       .overlay-category-tree-content { width: 380px; margin: 0 0 0 auto; box-sizing: border-box; box-shadow: $shadow01; }
    }
    .layout-overlay-content { flex: 0 0 100% !important; max-height: 100%; }
    .layout-overlay-content-wrapper,
    .ebeauty-dialog-content { height: 100%; display: flex; flex-direction: column; flex: 0 0 100%; overflow: hidden; }
    .ebeauty-dialog-content { display: flex; flex-direction: column; overflow: hidden; flex: 0 0 100%; }
    &__main { flex: 0 1 100%; overflow: hidden; overflow-y: auto; padding: $gridunit; box-sizing: border-box; }
    &__footer {
      flex: 0 0 auto; padding: $gridunit; box-sizing: border-box; border-top: 1px solid $brand-gray-300;
      .layout-cart__sidebar-total { margin: 0; font-size: $font-size-medium-large; }
      .buttons.layout-vertical-wide { margin-top: $gridunit-small; }
    }
  }
}

.overlayholder.overlayholder-focusview {
  @include mobile { z-index: 999 !important; }
}

.overlay-search {
  .alternate-blocks { max-width: 100%; }
}

.overlay-focusview-content {
  display: flex; flex-direction: column;
}

.overlay-focusview-scroller {
  @include mobile {
    flex: 0 1 100% !important; height: auto !important;
  }
}
