.layout-hero {
  &__header {
    min-height: 400px; margin: 0 $gridunit-large*-1; background-repeat: no-repeat; background-position: 50% 0; background-size: auto 400px;
    display: flex; justify-content: center; align-items: flex-end;  padding: 0 $gridunit-large;
    @include not-desktop { margin: 0 $gridunit*-1; padding: 0 $gridunit; }

    &-inner {
      position: relative; top: $gridunit; background: $color-white; flex: 0 0 auto; padding: $gridunit; box-sizing: border-box; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius;
      @include not-mobile { min-width: 600px; max-width: 900px; }
      @include mobile { flex: 0 0 100%; margin-top: 140px; }
    }

    H1 { margin: 0; }
    .layout-hero__limiter { display: flex; justify-content: flex-start; padding: 0 $gridunit; }
    .header-meta {
      display: flex; margin: 0 $gridunit*-1;
      @include not-desktop {
        flex-wrap: wrap;
      }
      &__item {
        margin: $gridunit 0 0 0; padding: 0 $gridunit; flex: 0 0 auto; border-right: 1px solid $brand-gray-200;
        &:last-child { border-right: none; }
        @include mobile { flex: 0 0 100%; border-right: none; margin: $gridunit-large 0 0; }
        IMG { width: 17px; height: 17px; }
      }
      &__title { margin: 0; font-size: $base-font-size; font-weight: $bold; }
      DL {
        margin: $gridunit-small 0 0; padding: 0; min-width: 200px; display: grid; grid-gap: 0; grid-template-columns: repeat(2, 50%);
        @include mobile { grid-template-columns: 7em auto; margin: 0; }
        DT, DD { padding: 0; margin: $gridunit-small 0 0; }
        DT {
          font-weight: $bold; padding-right: $gridunit; box-sizing: border-box; white-space: nowrap;
        }
      }
      P {
        IMG:first-child { margin-right: $gridunit-small; }
      }
    }
  }
  &__body { line-height: 1.4; }
  &__limiter {
    width: 100%; max-width: $content-maxwidth; margin-left: auto; margin-right: auto; padding: $gridunit-large $gridunit; box-sizing: border-box;
  }
}
