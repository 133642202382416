// Shopping cart

.layout-cart {
  margin: $gridunit; position: relative;
  &__sidebar {
    &-inner { background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius; margin: 30px 0 0; padding: $gridunit; box-sizing: border-box; }
    @include not-mobile {
      position: sticky; top: 0; align-self: flex-start; margin-top: -180px; padding-top: 165px;
      &-inner { display: flex; flex-direction: column; max-height: calc( 100vh - 220px ); }
      &-scroll { flex: 0 1 100%; @include overflow; @include scrollbars(); margin-right: -$gridunit-small; padding-right: $gridunit-small; }
    }
    H2 { margin: 0; }
    &-icon {
      float: left; width: 28px; height: 28px; margin: 0 $gridunit-small 0 0;
      display: flex; justify-content: center; align-items: center;
      IMG { height: 100%; width: auto; }
    }
    > .buttons {
      @include not-mobile { max-width: 100%; }
      > LI { flex: 0 0 100%; }
      @include not-desktop {
        .button { width: auto !important; min-width: 50% !important; max-width: none; }
      }
      @include mobile {
        .button { width: 100% !important; }
      }
    }
    .button-primary,
    .button.intent-primary { width: 100%; margin: $gridunit 0 0; }
    &-total { line-height: 1.2; font-size: $font-size-larger; font-weight: $bold; display: flex; justify-content: space-between; }
    @include mobile { margin-top: 50px; }
  }
  &__main {
    H1 { margin: 0; }
    .product-campaign:first-child { margin: 0; }
  }
  @include desktop {
    display: flex; flex-wrap: nowrap; align-items: flex-start;
    &__sidebar {
      flex: 0 0 280px; margin-left: $gridunit-large;
    }
    &__main { flex: 1 1 auto; max-width: calc(100% - 280px - #{$gridunit-large}); }
  }
}

.cart-products {
  border: 1px solid $brand-gray-200; margin: $gridunit 0 0;
  &__title {
    background-color: $brand-gray-200; padding: $gridunit-small; border-radius: $base-radius $base-radius 0 0;
    H2 { font-size: $base-font-size; font-weight: $normal; margin: 0; }
    IMG { margin-right: $gridunit-small; }
  }
  @include mobile { border: none; }
  &__item {
    margin: $gridunit $gridunit-small 0; padding-bottom: $gridunit-small; border-bottom: 1px solid $brand-gray-200;
    .ebeauty-dialog-minicart & {
      margin: $gridunit 0 0;
      .box-product { padding: 0 $gridunit-small; }
    }
    &:last-child { border-bottom: none; }
    .box-product {
      padding: 0; position: relative; width: auto;
      &__quantity { margin: 0; }
      &__prices { flex: 0 0 120px; margin: 1.25em 0 0; }
      .product-pricing {
        flex-direction: column; align-items: flex-end;
        &__price {
          P:first-child:last-child { padding-bottom: 0; }
          &-value:first-child { margin-top: 0; }
          &-number { font-size: $font-size-medium-large; line-height: 1.4; }
          &.special {
            P { display: flex; }
            .product-pricing__price-label {
              text-indent: -10000px; display: inline-block; width: 16px; margin: $gridunit-small/2 $gridunit-small/2 0 0; height: 16px; position: relative;
              IMG { width: 16px; height: 16px; border-radius: 3px; position: absolute; left: 0; top: 0; }
            }
          }
        }
      }
      &__main { overflow: hidden; }
      &__description-secondary {
        SPAN { white-space: normal; word-wrap: break-word; }
      }
      &__total {
        flex: 1; text-align: left; margin-left: $gridunit-small;
        P {
          margin: 0; font-size: $font-size-small; font-weight: $medium-bold; text-transform: uppercase;
        }
        &-prices {
          margin-left: $gridunit-small/2; flex: 0 0 120px;
        }
        SPAN {
          display: block; font-size: $font-size-medium-large; color: $brand-gray-placeholder; white-space: nowrap;
          &.text-accented { color: $brand-primary-800-wcag; }
          &.discount {
            font-weight: $normal; position: relative;
            &:before {
              content: ""; position: absolute; z-index: 1; top: 50%; left: -2%; width: 100%; height: 1px; background: $color-gray-medium-light; transform: rotate(-13deg);
            }
          }
        }
        IMG { width: $gridunit; height: $gridunit; border-radius: 3px; }

      }
      &__remove {
        position: relative; border: 1px solid $brand-gray-350; background-color: $brand-gray-300; margin-left: $gridunit-large;
        text-indent: -10000px; width: 38px; height: 38px; box-sizing: border-box; cursor: pointer; border-radius: $base-radius;
        &:hover { background-color: $brand-gray-350; }
        &:focus { box-shadow: $shadow-focus2; }
        IMG { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: $gridunit; height: $gridunit; margin: auto; }
      }
      &__loyalty {
        align-items: center; line-height: 1.3; position: relative; padding-left: 30px;
        STRONG { display: block; }
        IMG { margin-right: $gridunit-small; width: 22px; height: 22px; position: absolute; left: 0; top: 0; }
      }
      &__actions {
        display: flex; align-items: center; flex-wrap: wrap; flex: 0 0 100%; box-sizing: border-box; justify-content: space-between;
        &-group {
          display: flex; align-items: center; flex: 0 0 auto; align-self: flex-start; margin: $gridunit 0 0;
          &:first-child { min-width: 24rem; }
        }
      }
      &__content { padding-bottom: .2em; }
      .system-notifications { flex: 0 0 100%; }

      @include not-mobile {
        flex-direction: row; flex-wrap: wrap; align-items: center;
        &__main {
          display: flex; margin-right: $gridunit-large; flex: 0 1 100%; flex-direction: row; align-items: center;
        }
        &__image { flex: 0 0 100px; min-height: 100px; margin-right: $gridunit-large; }
        &__actions {
          padding-left: 125px;
          &-group { margin-top: 0; }
        }
        &__quantity { margin-left: $gridunit; }
        .product-pricing { align-items: flex-start; }
      }
      @include desktop-very-wide {
        flex-wrap: nowrap; align-items: center;
        .system-notifications { order: 2; }
        &__remove { position: absolute; right: $gridunit-small; top: 50%; margin-top: -25px; }
        &__actions { flex: 0 0 400px; padding-left: 0; margin-right: 70px; margin-left: 0; justify-content: space-between; }
      }
      @include mobile {
        &__actions {
          display: block; min-width: 100%;
          &-group { min-width: 100%; flex: 0 0 100%; justify-content: space-between; flex-wrap: wrap; }
        }
        &__prices,
        &__total {
          min-width: 200px;
        }
        &__prices,
        &__quantity { margin: $gridunit 0 0; }
        &__total {
          display: flex; justify-content: space-between; margin-left: 0; margin-top: $gridunit-small;
          SPAN { font-size: $font-size-large; margin-right: $gridunit; }
          P { flex-direction: column; align-items: flex-start; }
          &-prices {
            flex: 0 0 auto; margin-left: 0; display: flex !important; flex-direction: row-reverse; }
        }
        &__remove { margin-top: $gridunit-small; }
        .product-pricing {
          flex-direction: row-reverse; justify-content: flex-end;
          &__price { margin-right: $gridunit; }
        }
        &__brand { font-size: $font-size-medium-small; }
        &__title {
          font-size: $font-size-medium-small;
          .tag { font-size: $font-size-smallest; }
        }
      }
      @include mobile-wide {
        &__main { overflow: visible !important; padding-left: 100px; min-height: 100px; position: relative; border-bottom: 1px solid $brand-gray-200; }
        &__image {position: absolute; left: 0; top: 0; width: 80px; height: 80px; min-height: 0; }
        &__actions {
          &-group:first-child { margin-top: 0; }
        }
      }
      @include mobile-small {
        &__quantity { float: left; }
      }
      .ebeauty-dialog-minicart & {
        flex-wrap: wrap; overflow: hidden;
        &__actions {
          display: block; min-width: 100%; flex: 0 0 100%; padding-left: 0;
          &-group { min-width: 100%; flex: 0 0 100%; justify-content: space-between; flex-wrap: wrap; }
        }
        &__image { flex: 0 0 50px; min-height: 50px; }
        @include mobile {
          &__image { flex: 0 0 200px; min-height: 200px; }
        }
        &__prices,
        &__total {
          min-width: 200px;
        }
        &__prices,
        &__quantity { margin: $gridunit 0 0; }
        &__total {
          display: flex; justify-content: space-between; margin-left: 0; margin-top: $gridunit-small;
          SPAN { font-size: $font-size-large; margin-right: $gridunit; }
          P { flex-direction: column; align-items: flex-start; }
          &-prices {
            flex: 0 0 auto; margin-left: 0; display: flex !important; flex-direction: row-reverse; }
        }
        &__remove { margin-top: $gridunit-small; top: auto; bottom: 0; right: 0; }
        .product-pricing {
          flex-direction: row-reverse; justify-content: flex-end;
          &__price { margin-right: $gridunit; }
        }
      }
    }
    @include mobile { margin: $gridunit-small 0 0; padding: $gridunit-small $gridunit; border: 1px solid $brand-gray-300; border-radius: $base-radius; }
  }
}

.cart-actions {
  display: flex; justify-content: space-between; flex-wrap: wrap;
  &__primary,
  &__secondary { flex: 0 0 auto; max-width: 100%; }
  &__primary { display: flex; }
  @include mobile {
    &__primary,
    &__secondary {
      flex: 0 0 100%;
      .buttons.layout-default .button { width: auto; display: inline-flex; }
    }
  }
}

.cart-block {
  border-radius: $base-radius; margin: $gridunit 0 0; line-height: 1.5;
  P { margin: 0; font-weight: $medium-bold; }
  &.theme01 { background-color: $brand-gray-200; padding: $gridunit-small; }
  &.theme02 { background-color: $brand-accent-100; padding: $gridunit-small; }
  &.theme03 {
    background-color: $color-white; border: 1px solid $brand-gray-300; padding: $gridunit-small;
    P {
      font-weight: $normal;
      &.bold { font-weight: $medium-bold; }
      &.meta { font-size: $font-size-small; padding: $gridunit 0; }
      .text-accented { font-weight: $medium-bold; }
    }
  }
  FORM {
    margin: $gridunit 0 0;
    .form-control { width: 100%; }
    .button { margin: $gridunit-small 0 0; }
  }
  LEGEND {font-weight: $normal !important; }
  UL.checkboxes {
    margin: 0 !important;
    > LI { margin-top: 0; }
  }
  .inline .system-notification {
    padding: $gridunit-small;
    &__icon { margin-left: 0; margin-right: $gridunit-small; }
  }
  .cart-button { padding: 0 $gridunit; }
}

.cart-prices {
  margin: $base-margin; line-height: 1.4;
  > LI { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; margin: $gridunit-small 0 0; }
  .primary { font-weight: $bold; padding-bottom: $gridunit-small; }
  .separated { border-top: 1px solid $brand-gray-300; margin: $gridunit 0 0; padding-top: $gridunit; }
  .label { flex: 1 1 auto; }
  .value { flex: 0 0 auto; padding-left: 8px; font-weight: $bold; }
  IMG { height: 16px; margin-right: $gridunit-small/2; }
}
