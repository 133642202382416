.tabbed-content {
    margin: $gridunit-large 0 0;
    &__tabs {
        position: relative;
        @include desktop {
            display: flex; margin: 0;
            > LI {
                margin: 0 2px 0 0; display: flex; flex-direction: column;
                > A {
                    position: relative; text-decoration: none !important; display: flex; justify-content: center; align-items: center; text-align: center; flex: 1; color: $color-text-dark; padding: $gridunit $gridunit-large; text-transform: uppercase; font-weight: $bold; background-color: $brand-gray-200; border: 1px $brand-gray-300; border-style: solid solid none solid;
                    &:active,
                    &.active { background-color: $color-white; border-color: $brand-gray-350; z-index: 2; }
                    &:hover { background: $brand-gray-300; text-decoration: none; }
                    &:focus { box-shadow: $shadow-focus2; }
                }
            }
            .vertical & {
                flex-direction: column; flex: 0 0 180px; padding-right: $gridunit; border-right: 1px solid $brand-gray-300; margin-right: $gridunit;
                > LI {
                    margin: 2px 0 0;
                    &:first-child { margin: 0; }
                    > A {
                        background-color: $color-white; text-align: left; border: 1px $brand-gray-350; border-style: solid; justify-content: flex-start; border-radius: $base-radius; padding: $gridunit-small*1.5 $gridunit;
                        &:active,
                        &.active { background-color: $color-system-info-200; border-color: $color-system-info-200; z-index: 2; }
                        &:hover { background: $color-system-info-250; text-decoration: none; }
                        &:focus { box-shadow: $shadow-focus2; }
                    }
                }
            }
        }
        @include not-desktop {
            display: none;
        }
        &.align-center {
            display: flex; justify-content: center;
        }
    }
    &__panel {
        @include desktop {
            padding: $gridunit; border: 1px solid $brand-gray-350; margin: -1px 0 0;
            &:not(.open) { position: absolute; opacity: 0; pointer-events: none; top: 0; left: 0; right: 0; bottom: 0; }
            &.open { position: static; opacity: 1; pointer-events: all; }
            .vertical & { border: none; margin: 0; padding: 0; }
            .minimal & { border: none; margin: 0; padding: $gridunit 0 0; }
        }
        &-group {
            position: relative;
            .vertical & { flex: 0 1 100%; }

        }
        @include not-desktop {
            margin: 2px 0 0;
            .vertical & { margin: $gridunit-small 0 0; }
        }
        &-anchor {
            position: relative; top: -200px;
            @include not-mobile { display: none; }
            @include mobile { top: -150px; }
        }
        &-heading {
            @include not-desktop {
                border: 1px solid transparent; padding: $gridunit $gridunit*2; background-color: $color-system-info-200; cursor: pointer;
                &:active,
                &:hover { background: $color-system-info-250; text-decoration: none; }
                &:focus { box-shadow: $shadow-focus2; }
                &[aria-expanded="true"] {
                    border-color: $brand-gray-350; background-color: $color-white;
                }
                .mobile-collapse &[aria-expanded="true"] {
                    border-color: transparent; background-color: $color-system-info-200;
                    &.mobile-open { border-color: $brand-gray-350; background-color: $color-white; }
                }

                H2 { margin: 0; text-transform: uppercase; font-size: $base-font-size; font-weight: $bold; }

                .vertical & {
                    border: 1px solid $brand-gray-350; padding: $gridunit; background-color: $color-white; cursor: pointer; border-radius: $base-radius;
                    &:active,
                    &:hover { background: $color-system-info-250; text-decoration: none; }
                    &:focus { box-shadow: $shadow-focus2; }
                    &[aria-expanded="true"] {
                        border-color: $color-system-info-200; background-color: $color-system-info-200;
                    }
                }
            }
            @include desktop {
                position: absolute; left: -10000px;
                .vertical & {
                    position: static;
                    H2 { margin: 0; font-size: $font-size-larger; font-weight: $bold; }
                }
            }
        }
        &-content {
            line-height: 1.5;
            > *:first-child:not(.buttons):not(H3) { margin-top: 0; }
            .form-list { margin-top: $gridunit; }
            @include not-desktop {
                padding: $gridunit; border: 1px $brand-gray-350; border-style: none solid solid;
                .mobile-collapse &:not(.mobile-open),
                &[aria-hidden="true"] { position: absolute; opacity: 0; pointer-events: none; left: 0; right: 0; top: 0; }
                .elevated & {
                    margin: $gridunit-small 0 0; border: none; background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius;
                }
            }

            H3 {
                margin: $gridunit-large 0 0; font-size: $base-font-size; font-weight: $bold;
                + P { margin: 0; }
            }
        }
    }
    &.vertical {
        @include desktop {
            display: flex;
        }
    }
    &.elevated {
        @include desktop {
            background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius; padding: $gridunit;
        }
    }
    &.style-minimal {
       .tabbed-content {
           &__tabs {
               > LI > A {
                   border: none;
                   &,
                   &.active,
                   &:active { background-color: transparent; }
                   &.active,
                   &:active { color: $brand-primary-800; }
                   &:hover { background-color: transparent; color: $brand-primary-800; }
               }
               @include not-desktop {
                   display: flex; margin: 0;
                   > LI {
                       margin: 0 2px 0 0; display: flex; flex-direction: column;
                       > A {
                           position: relative; text-decoration: none !important; display: flex; justify-content: center; align-items: center; text-align: center; flex: 1; color: $color-text-dark; padding: $gridunit $gridunit-small; font-weight: $bold;
                           &:active,
                           &.active { z-index: 2; }
                           &:hover { text-decoration: none; }
                           &:focus { box-shadow: $shadow-focus2; }
                       }
                   }
               }
           }
           &__panel {
               border: none; padding: 0; margin: $gridunit 0 0;
               &-content { border: none; padding: 0; }
               &-heading { display: none !important; }
           }
       }
    }
}
