// Grid layout for promotions

.layout-grid {
  line-height: 1.4;
  @include mobile {
    margin: 0;
    &__container { padding: $gridunit 0 0 0; }
    &__columns { display: flex; flex-direction: column; align-items: stretch; }
  }
  @include not-mobile {
    display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 $gridunit; margin: 0 $gridunit*-1 0 $gridunit*-1; justify-content: space-between;
    &__container {
      flex: 0 0 50%; max-width: 650px; box-sizing: border-box; padding: $gridunit $gridunit 0 0; display: flex; flex-direction: column;
      &.wide { flex: 0 0 100%; max-width: 100%; }
      > *:first-child { margin: 0; }
    }
    &__columns {
      display: flex;
      &.reverse { flex-direction: row-reverse; }
      &.align-v-center { align-items: center; }
    }
    &__column {
      flex: 1;
      > *:first-child { margin-top: 0; }
      &.separated {
        margin-left: $gridunit; padding-left: $gridunit; border-left: 1px solid $brand-gray-300;
        .vertical & { margin-left: 0; padding-left: 0; margin-top: $gridunit; border-top: 1px solid $brand-gray-300; border-left: none;}
      }
    }
    &.vertical { flex-direction: column; }
  }
  @include defaultMargin($gridunit);
}
