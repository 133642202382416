.frame-ebeauty {
  .carousel {
    &.carousel-inline {
      .react-multi-carousel-list .control-arrow  {
        opacity: 1;
      }
      .control-arrow {
        overflow: visible;
        &:hover { background-color: transparent; }
        &:focus { background-color: transparent; }
        &:before {
          border-color: $brand-gray-200;
          box-shadow: $shadow03;
        }
        svg path { fill: $brand-primary-600; }
      }
    }
    .slider { background-color: transparent; }
    .slide { background-color: transparent; }
    .thumbs-wrapper {
      .control-arrow { display: none; }
    }
  }
}
