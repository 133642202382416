// Product box

.box-product {
  background-color: $color-white;
  position: relative; z-index: 0; font-size: $base-font-size; line-height: 1.5; box-sizing: border-box;
  flex: 1 0 auto; display: flex; flex-direction: column; padding: $gridunit-small; width: 276px;
  .react-multi-carousel-list & { margin: 0 auto; width: 100%; max-width: 276px; }
  &.special {
    .light &,
    & {
      background-color: $color-accent-product;
      .product-pricing__price-perunit,
      .box-product__description-secondary { color: $color-text-dark; }
    }
    .box-product__image {
      SPAN { width: 200px; height: 200px; border-radius: 50%; background-size: cover;}
    }
  }
  &.selectable {
    cursor: pointer; margin-left: 0 !important; margin-right: 0 !important;
    A { display: none; }
  }

  &:hover &__title { color: $brand-accent-wcag; }
  &__main { position: relative; flex: 1 1 auto; display: flex; flex-direction: column; }
  &__actions {
    flex: 0 0 auto;
    .button {
      display: block; margin: $gridunit-small 0 0; width: 100%;
      &.intent-success { background-color: $color-system-success-800; color: $color-white; font-weight: $medium; }
      @include not-desktop-normal {
        font-size: $font-size-small !important;
        IMG {
          &:first-child { margin-right: $gridunit-small/2 !important; margin-left: $gridunit-small/-2 !important; }
        }
      }
    }
  }
  &__cart {
    position: absolute; z-index: 4; left: 0; right: 0; top: 0; background: $color-system-success-200; padding: $gridunit; color: $color-system-success-800; text-align: center; font-weight: $normal;
    IMG { margin-right: $gridunit-small/2; }
    A { display: block; color: $color-system-info-1000; }
  }
  &__link {
    position: absolute; left: 0; right: 0; top: 0; bottom: 0; color: rgba(0, 0, 0, 0) !important; z-index: 3;
    &:focus {
      ~ .box-product__content .box-product__title { text-decoration: underline; }
    }
  }
  &__image {
    position: relative; padding: 0; display: flex; flex-wrap: wrap; min-height: 202px; justify-content: space-between;
    span { position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain; }
  }
  &__badges {
    position: relative; z-index: 2; display: flex; flex-direction: column; align-items: flex-start;
    &:first-child:last-of-type { flex: 0 0 100%; align-items: flex-end; }
    IMG {
      .carousel & { width: auto; }
    }
  }
  &__badge {

    &-square {
      margin: $gridunit-small/2 0 0; padding: 2px $gridunit-small/2; background: $brand-badge02-background; color: $brand-badge02-text; border-radius: 20px; font-size: $font-size-smaller; font-weight: $bold;
      HTML & IMG { margin-right: $gridunit-small/2; max-width: 48px !important; min-width: 32px; height: auto; }
      &.theme01 { background: $brand-badge01-background; color: $brand-badge01-text; }
      &.theme02 { background: $brand-badge02-background; color: $brand-badge02-text; }
      &.theme03 { background: $brand-badge03-background; color: $brand-badge03-text; }
      &.theme04 { background: transparent; }
      .layout-product__image & {
        @include not-mobile {
          padding: $gridunit-small/2 $gridunit; font-size: $base-font-size;
        }
      }
    }
    &-circle {
      margin: $gridunit-small/2 0 0; width: 4em; min-height: 4em; font-weight: $bold; font-size: $font-size-small; display: flex; align-items: center; justify-content: center; padding: $gridunit-small/2; border-radius: 3em;
      &.theme01 { background: $brand-badge03-background; color: $brand-badge03-text; }
      &.theme02 { background: $brand-badge04-background; color: $brand-badge04-text; }
    }
  }
  &__content { flex: 1 1 auto; display: flex; flex-direction: column; overflow: hidden; }
  &__text { flex: 1 0 auto; display: flex; flex-direction: column; }


  &__title {
    max-height: 6em; overflow: hidden; flex: 0 1 100%; font-size: $font-size-medium-small;
    .cart-products__item & { overflow: visible; max-height: none; }
  }
  &__brand { text-transform: uppercase; font-size: $font-size-medium-large; letter-spacing: 0.15em; font-weight: $medium; color: $color-text-medium; }
  &__meta { font-size: $font-size-small; color: $color-text-medium; }
  &__description {
    font-size: $font-size-small; position: relative; padding-left: $gridunit-large; text-align: left; margin: 1.5em 0 0;
    .center & { text-align: center; }
    P { margin: 0; }
    &-icon { position: absolute; left: 0; top: 0; width: $gridunit; height: $gridunit; }
    &-primary {
      font-weight: $bold; letter-spacing: 0.01em;
      SPAN { color: $brand-gray-placeholder; }
      &:first-child { margin-left: -$gridunit-large; }
    }
    &-secondary {
      color: $brand-gray-placeholder; font-style: italic;
      SPAN { display: block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
      &:first-child,
      &:nth-child(2) { margin-left: -$gridunit-large; }
    }
    &-tertiary {
      color: $brand-gray-placeholder; margin-top: $gridunit !important;
      SPAN { font-weight: $bold; }
    }
  }
  &__prices {
    margin: .7em 0 0;
    .compact & { margin: 0; }
    .product-pricing {
      .center & { justify-content: center; }
    }
    @include not-mobile {
      .cart-products__item & {
        &:first-child .product-pricing { flex-direction: column-reverse; }
      }
    }
    /*+ .button { margin-top: 3.7em; }*/ // Todo: temporary
  }
  &__total {
    .cart-products__item &-prices {
      @include not-mobile { margin-left: 0; }
    }
  }
  &__quantity {
    /*display: flex;*/ justify-content: flex-start; margin: $gridunit 0 0; min-height: 2.3em;
    display: none !important; // Todo: temporary
    .cart-products__item & { display: flex !important; }
    .loading-content { align-self: center; }
    BUTTON {
      cursor: pointer; overflow: hidden; position: relative; z-index: 1; background-color: $brand-gray-300; border: 1px solid $brand-gray-350; text-indent: -10000px; color: transparent; padding: $gridunit-small $gridunit; font-weight: $bold;
      &:before { color: $color-text-dark; }
      &:first-child {
        border-radius: $base-radius 0 0 $base-radius;
        &:before { content: "-"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
      }
      &:last-child {
        border-radius: 0 $base-radius $base-radius 0;
        &:before { content: "+"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
      }
      &:disabled { pointer-events: none; color: transparentize($color-text-dark, .5); }
      &:hover { background-color: $brand-gray-350; }
      &:active { background-color: $brand-gray-350; border-color: $brand-gray-600; }
      &:focus { background-color: $brand-gray-350; box-shadow: $shadow-focus2; }
    }
    INPUT {
      border: 1px solid $brand-gray-350; margin: 0 -1px; flex: 0 0 auto; width: 4rem; padding: $gridunit-small $gridunit-small/2; text-align: center;
      &:focus { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px transparentize($brand-focus-1, .6); position: relative; z-index: 2; }
      &:disabled { pointer-events: none; color: transparentize($color-text-dark, .5); }
    }
  }

  .checkboxes & {
    @include not-desktop {
      &__main { margin-left: $gridunit-small*-1; align-items: center; }
      &__brand { font-size: $font-size-small; word-wrap: break-word; }
      &__title { font-size: $font-size-medium-small; }
      &__image { width: 120px; height: 120px; flex: 0 0 120px; align-self: center; }
    }
  }

  // On gray background

  @at-root .gray & { background-color: $color-white; }

  // Light

  @at-root .light & { background-color: transparent; }

  // List view

  @at-root .list & {
    flex-direction: row; flex-wrap: nowrap; text-align: left; align-items: center; padding-right: 0;
    @include not-desktop { align-items: flex-start; }
    &__image { flex: 0 0 200px; max-width: 200px; height: 200px; padding: 0; margin-right: 20px; }
    &__description { display: block; font-size: 13px; margin-top: 10px; }
    &__pre-title { position: static; transform: none; }
    &__title { margin-top: 0; }
    &__swatches { align-items: flex-start; justify-content: flex-start; }
    &__price {
      margin-top: 10px;
      &.old { margin-top: 0; font-size: 12px; }
    }
    @include mobile {
      &__image { flex: 0 0 70px; max-width: 70px; height: 70px; margin-right: 15px; }
      &__description { font-size: 12px; }
    }
    @include tablet {
      &__image { flex: 0 0 150px; max-width: 150px; height: 150px; }
    }
  }

  @at-root .upsell & {
    max-width: none; width: auto; padding: 0;
    &:hover .box-product__title { color: $color-text-dark; }
    &__actions {
      display: flex; justify-content: space-between; margin: $gridunit $gridunit-small/-2 0; flex-wrap: wrap;
      .button { margin: $gridunit-small/2; flex: 1; min-width: 250px; }
    }
  }

  @include mobile {
    width: 100%; padding: $gridunit-small $gridunit;
    &__title {
      font-size: $font-size-medium-large; font-weight: $bold; line-height: 1.2; max-height: 1.2*4;
      .cart-products__item & { overflow: visible; max-height: none; }
    }
    &__image { height: 40vw; max-height: 320px; }
    &__description { font-size: $font-size-smaller; }
  }
  @include mobile-small {
    &__image { height: 80vw; max-height: 120px; }
  }
}
