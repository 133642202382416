// Units

$gridunit: 20px !default;
$gridunit-small: 10px !default;
$gridunit-large: 30px !default;
$uispeed: 250ms !default;
$uieasing: cubic-bezier(0.42, 0, 0.58, 1) !default;
$base-radius: 3px !default;
$content-maxwidth: 1200px !default;

$spacer-desktop: 30px !default;
$spacer-desktop-vertical: 30px !default;
$spacer-tablet: 30px !default;
$spacer-tablet-vertical: 30px !default;
$spacer-mobile: 20px !default;
$spacer-mobile-vertical: 20px !default;

$grid-gap: 24px !default;
$column-width: 78px !default;

$modalZ: 16000000 !default;

// Colors

$color01: #031b30 !default;
$color02: #076594 !default;
$color03: #e1eaef !default;
$color04: #f3f6fa !default;
$color05: #ef5d0d !default;

$color-white: #fff !default;
$color-black: #333 !default;
$color-link: $color02 !default;
$color-link-hover: change_color($color02, $lightness: 40%, $saturation: 80%) !default;
$color-disabled: rgba($color-black, 0.5) !default;
$color-text-medium: lighten($color-black, 30) !default;
$color-text-dark: $color-black !default;
$color-text-light: $color-white !default;

$color-gray-lightest: #f5f5f5 !default;
$color-gray-lighter: #ededed !default;
$color-gray-light: #e6e6e6 !default;
$color-gray-medium: #aaa !default;
$color-gray-dark: #666 !default;
$color-gray-darker: #444 !default;
$color-gray-darkest: #222 !default;

$color-primary: $color02 !default;
$color-success: #74a12f !default;
$color-warning: #C07600 !default;
$color-danger: #FF2D1E !default;
$color-disabled: $color-gray-medium !default;
$color-active: $color05 !default;

// Sizes

$mobile-header-height: 50px !default;
$comment-width: 280px !default;

$input-height: 40px !default;
$input-background: $color-white !default;
$input-background-focus: $color-white !default;
$input-border-color: rgba($color-black, 0.2) !default;
$input-border-color-hover: rgba($color-black, 0.25) !default;
$input-border-color-focus: rgba($color-black, 0.30) !default;

$button-default-height: $input-height !default;
$button-small-height: $gridunit !default;

$base-padding: 0 $gridunit $gridunit $gridunit !default;
$base-padding-large: 0 $gridunit-large $gridunit-large $gridunit-large !default;

$base-padding-mobile: 0 $spacer-mobile $spacer-mobile-vertical $spacer-mobile !default;
$base-padding-tablet: 0 $spacer-tablet $spacer-tablet-vertical $spacer-tablet !default;
$base-padding-desktop: 0 $spacer-desktop $spacer-desktop-vertical $spacer-desktop !default;

$base-margin: $gridunit 0 0 0 !default;
$base-margin-large: $gridunit-large 0 0 0 !default;

$base-font-size: 14px !default;
$font-size-smallest: 10px !default;
$font-size-smaller: 12px !default;
$font-size-small: 13px !default;
$font-size-medium: 16px !default;
$font-size-large: 20px !default;
$font-size-larger: 26px !default;
$font-size-largest: 32px !default;

$base-line-height: 22px !default;
$line-height-small: 16px !default;
$line-height-medium: 26px !default;
$line-height-large: 30px !default;

$shadow01: 0 8px 32px 0 rgba($color-black, 0.1) !default;

$shadow02: 0 $gridunit / 2 $gridunit $gridunit / -2 $color-black !default;
$shadow03: 0 $gridunit / 2 $gridunit / 2 $gridunit / -2 rgba($color-black, 0.2) !default;
$shadow-menus: 0 0 0 1px $color-gray-light, 0 $gridunit / 4 $gridunit / 2 $gridunit / -4 $color-black !default;

$bold: 700 !default;

$intents: (
		"primary": $color-link,
		"success": $color-success,
		"warning": $color-warning,
		"danger": $color-danger,
		"active": $color-active,
);
