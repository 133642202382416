// Site menu

.site-menu {
  flex: 0 0 auto; display: flex; max-width: 100%; position: relative;

  @include desktop {
    flex: 1 1 auto; justify-content: space-between;
    LI { flex: 0 0 auto; }
  }

  @include not-desktop {
    flex-direction: row; align-items: center; flex: 0 0 25%;
  }

  .menutoggler {
    padding: $gridunit-small; box-sizing: border-box; flex: 1; display: flex; flex-direction: column; justify-content: center; align-items: center;
    .icon { margin: $gridunit-small; width: $gridunit-large; height: $gridunit-large; }
    @include desktop { display: none; }
  }
  A { text-decoration: none; }
  NAV,
  NAV > UL,
  NAV > UL > LI { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; position: static; }
  NAV, NAV > UL { flex: 1 0 auto; }
  NAV {
    @include not-desktop { display: none !important; }
    UL { padding: 0; margin: 0; }
    LI {
      padding: 0; margin: 0 !important;
      &:before { display: none; }
    }
    > UL {
      list-style: none;
      > LI {
        > A { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; padding: $gridunit; }
        > A,
        .#{$ns}-popover-wrapper  A {
          text-transform: uppercase; font-weight: $medium;
          .toggle-icon { display: none; }
        }
      }
      A {
        display: flex; position: relative; transition: all $uispeed $uieasing; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; padding: $gridunit; box-sizing: content-box;
        SPAN.toggle-icon {
          width: 20px; height: 20px; overflow: hidden; margin-right: $gridunit-small;
          IMG { width: 100%; height: auto; position: relative; }
        }
        SPAN.icon {
          width: 20px; height: 20px; overflow: hidden; margin-left: $gridunit-small;
          IMG { width: 100%; height: auto; position: relative; }
          svg { width: 100%; height: 100%; }
        }
        &:hover {
          background: $color-background-mainmenu-level1-hover;
          text-decoration: none;
        }
        .visible-focus &:focus { box-shadow: $shadow-focus inset; }
        &:active {
          background: $color-background-mainmenu-level1-hover;

        }
        &.active {
          background: $color-background-mainmenu-level1-selected; color: $color-text-mainmenu-level1-selected;
          .cat-label { color: inherit; }
          SPAN.toggle-icon,
          SPAN.icon {
            IMG { top: -20px; }
            svg {
              path { fill: $color-text-light; }
            }
          }
          &:hover {
            background: $color-background-mainmenu-level1-selected-hover;
            SPAN.toggle-icon IMG { top: -20px; }
          }
          &:active {
            background: $color-background-mainmenu-level1-selected-active; box-shadow: none;
            SPAN.toggle-icon IMG { top: -20px; }
          }
        }
      }
      .#{$ns}-popover-open > A {
        background: $color-background-mainmenu-level1-selected; color: $color-text-mainmenu-level1-selected;
        .cat-label { color: inherit; }
        SPAN.toggle-icon IMG { top: -20px; }
        &:hover {
          background: $color-background-mainmenu-level1-selected-hover;
          SPAN.toggle-icon IMG { top: -40px; }
        }
        &:active {
          background: $color-background-mainmenu-level1-selected-active; box-shadow: none;
          SPAN.toggle-icon IMG { top: -40px; }
        }
      }
      .badge {
        position: absolute; left: $gridunit-small/2; top: $gridunit-small/2; width: $gridunit; height: $gridunit;
        svg path { stroke: $brand-accent-wcag; }
      }
      UL {
        &.multigroup,
        &.singlegroup,
        &.single { display: none; }
      }
    }
  }

  .#{$ns}-popover-target { display: block; position: relative; }
  .#{$ns}-overlay-open UL.single { display: block; position: relative; }

  &-portal {
    .#{$ns}-transition-container { z-index: $modalZ + $pt-z-index-overlay; }
    &.main-nav {
      .#{$ns}-transition-container { width: 100vw; }
    }
  }

  &-popover {
    display: block; z-index: $modalZ + $pt-z-index-overlay;

    .#{$ns}-popover-content {
      max-width: 100%; padding: 0;
      @include clearfix;
      @include mobile { max-width: calc(100vw - 40px); }
      //&:focus { outline: none; }
      //*:focus { outline: none; }
    }
    .#{$ns}-transition-container {
      display: flex; z-index: $modalZ + $pt-z-index-overlay; padding-bottom: $gridunit / 2;
      //&:focus { outline: none; }
      &.#{$ns}-popover-leave .#{$ns}-popover-content { pointer-events: none; }
      &[data-x-out-of-boundaries] { display: none; }
    }

    //&:focus { outline: none; }

    .#{$ns}-overlay-inline { display: inline; overflow: visible; }
    .#{$ns}-popover {
      &-target { position: relative; display: block; }
    }
    .#{$ns}-submenu {
      .#{$ns}-popover {
        padding-top: 5px;
      }
    }

    .#{$ns}-menu {
      background-color: $color-white; border: none; border-radius: 0; font-size: 13px; box-shadow: $shadow01;
      > LI {
        border-top: 1px solid $color-gray-lighter; min-width: 200px;
        &:first-child { border-top: none; }
      }
      &-item {
        background-color: transparent; padding: 10px 20px; cursor: pointer; font-weight: 400;
        &:hover { text-decoration: none; background-color: rgba($color-link-hover, 0.05); color: $color-link-hover; }
        &.important { font-weight: $bold; color: $color-link; }
        &:focus { background: red; }
      }
    }

    &-single {
      UL { background: $color-white; box-shadow: $shadow-menus02; padding: $gridunit-large 0; }
      LI { margin-left: 0; }
      UL.site-menu-items > LI > A {
        border: none; color: $color-text-dark; padding: $gridunit-small $gridunit; text-align: left; display: block !important; text-transform: uppercase; font-weight: $bold;
        &:hover {
          background: $color-background-mainmenu-level2-hover;
          text-decoration: none;
        }
        &:active {
          background: $color-background-mainmenu-level2-active;
          text-decoration: none;
        }
        &.active {
          background: $color-background-mainmenu-level2-selected; color: $color-text-mainmenu-level2-selected;
          &:hover { background: $color-background-mainmenu-level2-selected-hover; }
          &:active { background: $color-background-mainmenu-level2-selected-active; }
        }
        .visible-focus &:focus { box-shadow: $shadow-focus; }
      }
    }
  }

  &-mobile {
    & &-heading {
      &.right { flex-direction: row-reverse; }
      background-color: $color-white; color: $color-text-dark; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: $gridunit;
      BUTTON {
        cursor: pointer; border: none; background-color: transparent; padding: 0; width: $gridunit; height: $gridunit; position: relative; color: currentColor;
        svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
        path { fill: currentColor; }
      }
      .logo { width: auto; height: $mobile-header-height - $gridunit; margin: $gridunit / 2 $gridunit; }
    }
  }

  &__drop {
    color: $color-text-dark; background: rgba(0,0,0,0.1); overflow: auto; border-top: 1px solid $color-gray-light;
    A {
      text-decoration: none;
      &:hover { text-decoration: underline; }
    }
    @include not-desktop { display: none; }
    &__limiter {
      background-color: $color-white; display: flex; box-shadow: $shadow-menus02;
      width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; position: relative; transition: all $uispeed $uieasing 0ms;
    }
    &__nav {
      flex: 0 0 230px; background: $color-white; border-right: 1px solid $color-gray-light; padding: $gridunit 0; box-sizing: border-box;
      .visible-focus &:focus { border: 1px solid red; }
      A {
        position: relative; display: flex; align-items: center; justify-content: space-between; padding: $gridunit-medium $gridunit; font-size: $font-size-medium-small; line-height: 1; letter-spacing: -0.001em; font-weight: $medium-bold;
        .badge { position: absolute; left: $gridunit-small/2; top: 1px; width: $gridunit; height: $gridunit; }
        svg path { fill: $color-text-dark; }
        &:hover {
          background: $color-background-mainmenu-level2-hover;
          text-decoration: none;
        }
        &:active {
          background: $color-background-mainmenu-level2-active;
          text-decoration: none;
        }
        &.active {
          background: $color-background-mainmenu-level2-selected; color: $color-text-light;
          .cat-label { color: inherit; }
          svg path { fill: $color-text-light; }
          &:hover { background: $color-background-mainmenu-level2-selected-hover; }
          &:active { background: $color-background-mainmenu-level2-selected-active; }
        }
        .visible-focus &:focus { box-shadow: $shadow-focus; }
      }
    }
    &__content {
      padding: 0 $gridunit-large $gridunit-large $gridunit-large; flex: 0 1 100%; display: flex; flex-direction: column;
      &:first-child UL {
        column-count: 4;
        UL { column-count: 1; }
      }
      UL {
        column-count: 3; flex: 0 1 100%;
        UL { column-count: 1; }
        LI {
          padding: 0; margin: 0;
          A {
            display: inline-block; position: relative;
            &:active,
            &.active { box-shadow: 0 0 0 1px $color-text-dark; }
            .badge { position: absolute; left: $gridunit-small/2; top: $gridunit-small/2; width: $gridunit; height: $gridunit; }
          }
          &:before { display: none; }
          &.level-3 {
            break-inside: avoid; padding: $gridunit/2 0;
            > A {  padding: 12px $gridunit; font-weight: $bold; font-size: $font-size-medium-small; }
            UL { padding: 0; }
          }
          &.level-4 {
            > A { padding: $gridunit-small/2 $gridunit; }
          }
        }
      }
      .banner {
        flex: 0 auto; margin-bottom: -$gridunit-large; display: flex;
        A {
          &:hover { background: none; }
        }
        IMG { display: block; }
      }
      IMG { max-width: 100%; }
    }

    &.open { opacity: 1; pointer-events: auto; }
    &.open &__limiter { transform: translate(0, 0); }
  }
  &__wrapper {
    display: flex;
    @include desktop {
      overflow: auto; -ms-overflow-style: none; scrollbar-width: none; scroll-behavior: smooth;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include not-desktop {
      justify-content: center; flex: 1;
    }
  }
  &__scroll {
    position: absolute; top: 0; bottom: 0; z-index: 1; border: none; background-color: transparent; overflow: hidden;
    svg { position: relative; z-index: 2; }
    &:before { content: ""; position: absolute; top: 0; bottom: 0; background-color: $color-background-header-primary; box-shadow: $shadow-menus; }
    &.left {
      left: -$gridunit-small; padding: 0 $gridunit 0 $gridunit-small;
      &:before { right: $gridunit-small; left: 0; }
    }
    &.right {
      right: -$gridunit-small; padding: 0 $gridunit-small 0 $gridunit;
      &:before { left: $gridunit-small; right: 0; }
    }
    @include not-desktop { display: none; }
  }
}

.overlay-mainmenu {
  &-content { background-color: $color-gray-darkest; color: $color-white; }
}

.nested-menu {
  &__item {
    &:first-child > A { margin-top: $gridunit-small; }
    > A {
      padding: $gridunit-small*1.5 $gridunit; color: $color-text-dark; text-transform: uppercase; font-weight: $medium-bold; letter-spacing: -0.01em; position: relative;
      display: flex; justify-content: space-between; align-items: center;
      .badge {
        position: absolute; left: $gridunit-small/2; top: $gridunit-small/2; width: $gridunit; height: $gridunit;
      }
    }
  }
  &__heading {
    padding: $gridunit; display: flex; justify-content: space-between; align-items: baseline;
    H2 { margin: 0; font-weight: $bold; font-size: $font-size-larger; line-height: 1; }
    A {
      padding: 0; color: $brand-primary-800-wcag; display: flex; align-items: center; white-space: nowrap; margin-left: $gridunit-small;
      svg {
        margin-left: $gridunit-small;
        path { fill: $brand-primary-800-wcag; }
      }
    }
  }
  &__back {
    margin: $gridunit-small 0 0; padding: $gridunit-small; border: none; background: none; display: flex; align-items: center; color: $color-text-dark; cursor: pointer;
    svg { margin: 0 $gridunit-small 0 0; }
  }
}

.overlayopen.mobilemenuopen {
  pointer-events: all;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  @include not-desktop {
    .scrollroot,
    .ebeautycy-root { position: static; height: auto; overflow-y: initial !important; pointer-events: all; }
  }
}

.brands-menu {
  &__alphabet {
    background-color: transparentize($brand-primary-200,.6); padding: 0 $gridunit-large; box-sizing: border-box;
    UL {
      column-count: auto !important; display: flex; flex-wrap: wrap;
      LI { flex: 0 0 20%; padding: $gridunit-small/4; box-sizing: border-box; }
    }
    BUTTON {
      display: block; width: 100%; box-shadow: none !important; background-color: transparent !important;
      &.active { background-color: $brand-primary-200 !important; }
    }
  }
  &__brands {
    padding: $gridunit $gridunit-large;
    &-group {
      &:not(:first-child) { margin-top: $gridunit-large; }
      H2 { margin: 0; color: $brand-primary-800-wcag; }
    }
  }
  @include desktop {
    &__inner {
      display: flex; margin: 0 $gridunit-large*-1 $gridunit-large*-1;
    }
    &__alphabet {
      flex: 0 0 280px;
    }
    &__brands {
      flex: 0 1 100%; margin: 0 0 $gridunit-small; max-height: 50vh; overflow: auto;
      UL {
        column-count: auto !important; display: flex; flex-wrap: wrap; margin: 0; padding: 0;
        LI { flex: 0 0 20%; padding-right: $gridunit-small; margin-top: $gridunit-small; box-sizing: border-box; }
      }
    }
  }
}
