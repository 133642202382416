.frame-ebeauty,
.ebeauty-dialog,
.overlay-search {
  .button {
    border-radius: 20px;
    IMG {
      height: 16px; width: auto;
      &:last-child { margin-left: $gridunit-small; }
      &:first-child { margin-right: $gridunit-small; }
      + SPAN { flex: 0 1 auto; white-space: normal; text-align: left; }
    }
    &.layout-default,
    & {
      height: auto; padding: $gridunit-small*1.5 $gridunit; font-size: $base-font-size; line-height: 1; display: inline-flex; justify-content: center;
      &:hover {
        opacity: 1; text-decoration: none;
        &:before { display: none; }
      }
      .text,
      .loader,
      .icon { color: inherit; }
      .text { max-width: 100%; display: flex; align-items: center; }
      .icon {
        &.intent-success { color: $color-system-success-800; }
      }
      &-primary,
      &.intent-primary {
        background-color: $brand-primary-800; color: $color-text-light; border: 1px solid $brand-primary-800; font-weight: $medium;
        &:hover { background-color: $brand-primary-900; border-color: $brand-primary-900; }
        &:active { background-color: $brand-primary-900; border-color: $brand-primary-600; }
        &:focus { box-shadow: $shadow-focus2; }
      }
      &-secondary,
      &.intent-secondary {
        background-color: transparent; border: 1px solid $brand-accent-wcag; color: $color-text-dark; font-weight: $normal;
        &:hover { color: $brand-accent-wcag; }
        &:active { color: $color-text-dark; }
        &:focus { box-shadow: $shadow-focus2; }
        &.muted { border-color: $brand-gray-350; background-color: $color-white; }
      }
      &-success,
      &.intent-success {
        background-color: $color-system-success-800; color: $color-text-light; border: 1px solid $color-system-success-800; font-weight: $medium;
        &:hover { background-color: darken($color-system-success-800, 5%); border-color: darken($color-system-success-800, 5%); }
        &:active { background-color: darken($color-system-success-800, 5%); border-color: $color-system-success-250; }
        &:focus { box-shadow: $shadow-focus2; }
      }
      &-tertiary,
      &.intent-tertiary {
        padding: $gridunit-small*1.5 0; color: $brand-primary-800-wcag; font-weight: $bold;
        SMALL { text-transform: none; font-size: inherit; }
      }
      &-discrete,
      &.intent-discrete {
        padding: $gridunit-small*1.5 0; text-decoration: underline; text-transform: none; color: $color-text-dark; font-weight: $normal;
      }
      &-discrete02 {
        padding: $gridunit-small*1.5 0; text-decoration: underline; text-transform: none; color: $brand-primary-800-wcag; font-weight: $normal;
      }
      &-idcard {
        background-color: #003164; border: 1px solid #003164; color: $color-text-light; text-transform: uppercase; font-weight: $bold;
        &:hover { background-color: #042241; }
        &:active { background-color: #042241; }
        &:focus { box-shadow: $shadow-focus2; }
      }
      &-facebook,
      &-google { color: $color-white; max-width: 220px; margin: 0; justify-content: flex-start !important; }
      &-facebook {
        background-color: #4267B2;
        &:hover { background-color: #274789; }
      }
      &-google {
        background-color: #4285F4;
        &:hover { background-color: #3677E1; }
      }
      &:not(:disabled),
      &:not(.disabled) { cursor: pointer; }
      &.disabled,
      &:disabled { pointer-events: none; opacity: 0.4; }
      &.size-small {
        height: auto; line-height: 1.5; align-items: center; padding: $gridunit-small/2 $gridunit-small; text-transform: none; font-size: $font-size-medium; font-weight: $normal;
        IMG { height: $gridunit; max-width: $gridunit; margin-right: $gridunit-small; }
        svg {
          margin-right: $gridunit-small;
          path { fill: $brand-primary-800; }
          &.icon-success path { fill: $color-success; }
        }
      }
      &.size-large {
        height: auto; line-height: 1.5; align-items: center; padding: $gridunit $gridunit-small;
      }
      &.size-xsmall {
        height: auto; line-height: 1.5; align-items: center; padding: $gridunit-small/4 $gridunit-small; text-transform: none; font-size: $font-size-medium; font-weight: $normal;
        IMG {
          height: $gridunit; margin-right: $gridunit-small;
          &:first-child { margin-left:0; }
        }
        svg {
          margin-right: $gridunit-small;
          path { fill: $brand-primary-800; }
        }
      }
    }
  }
  .buttons {
    margin: $gridunit-small $gridunit-small*-1 $gridunit-small/-2 $gridunit-small*-1;
    > LI { margin: $gridunit-small/2 $gridunit-small; align-items: center; }
    &.compact {
      > LI {
        max-width: 100%; margin: $gridunit $gridunit 0 0;
        &:first-child:last-child { margin: 0; };
      }
      .button-discrete {
        padding: 0; white-space: normal; text-align: left;
      }
      @include mobile {
        flex-direction: row; flex-wrap: wrap; justify-content: flex-start;
      }
    }
    &.full {
      > LI { flex: 1; }
      .button { width: 100%; min-width: 100%; }
    }
    &.vertical {
      flex-direction: column; max-width: 480px;
      .button { flex: 1; justify-content: center; }
    }
    &.layout-vertical-wide {
      max-width: 480px - $gridunit;
      &.align-center { margin-left: auto; margin-right: auto; }
    }
    &.margt { margin-top: $gridunit-large; }
    &.mobile-inline {
      @include mobile {
        flex-direction: row;
      }
    }
    &.mobile-full {
      @include mobile {
        .button { flex: 0 0 100%; }
      }
    }
    .button { display: inline-flex; }
    &-banklinks {
      display: flex; flex-direction: row; flex-wrap: wrap; max-width: 320px;
      &-item {
        flex: 0 0 auto; margin: $gridunit $gridunit 0 0; position: relative;
        &-image { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: transparent; background-size: 100% 100%; background-repeat: no-repeat; }
        &-label { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; }
      }

      BUTTON {
        display: block; width: 88px; height: 31px; border: 1px solid $color-gray-medium; background-color: $color-white; padding: 5px; cursor: pointer; flex: 0 0 auto;
        font-size: $font-size-smaller; line-height: 14px; color: $color-black;
        &.disabled,
        &[disabled] { opacity: 0.2; cursor: default; }
      }
    }
  }
}
