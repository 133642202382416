@import "../../../../../scss/variables";
@import "../../../../../scss/mixins";

.summary {
	padding: 0; list-style-type: none; @include defaultMargin;
	font-size: $font-size-small;
	line-height: 18px;
	&-list {
		@include not-mobile {
			max-height: 300px;
			overflow: hidden;
			overflow-y: auto;
			@include scrollbars();
		}
		&-item {
			padding: $gridunit * 0.75 $gridunit;
			.name {
				hyphens: auto;
				hyphenate-limit-chars: 6 3 3;
				hyphenate-limit-lines: 2;
				hyphenate-limit-last: always;
				hyphenate-limit-zone: 8%;
			}
			.meta { display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; }
			.amount { margin-right: $gridunit / 2; }
			.sum { font-weight: $bold; margin-left: $gridunit / 2; }
			.addon { flex: 1 1 auto; }
			.description {
				hyphens: auto;
				hyphenate-limit-chars: 6 3 3;
				hyphenate-limit-lines: 2;
				hyphenate-limit-last: always;
				hyphenate-limit-zone: 8%;
				font-size: 11px;
				line-height: 1.3;
				opacity: 0.7;
			}
		}
	}
	&-totals {
		padding: $gridunit * 0.75 $gridunit;
		.label { margin-right: $gridunit / 2; }
		.value { font-weight: $bold; }
		.row {
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; margin: 0;
			&.grandtotal {
				display: block; margin-top: 5px;
				.label { font-weight: $bold; }
				.value { font-weight: 400; font-size: $font-size-larger; line-height: 1.2; }
			}
		}
	}
	&-extra {
		padding: 0 $gridunit $gridunit * 0.75; @include clearfix;
		> *:first-child { margin-top: 0; }
	}
	&-title {
		padding: $gridunit; line-height: $gridunit; font-size: $font-size-large; margin: 0;
	}

	+ .callout { margin-top: 0 !important; }
	@include mobile { margin-top: 0; }
}
