// Promo box

.box-promo {
	background-color: $color-gray-lighter; background-size: cover; background-position: 50% 0; background-repeat: no-repeat; color: $color-text-dark;
	position: relative; z-index: 0; text-align: center; font-size: 17px; line-height: 1.5; box-sizing: border-box; min-height: 540px;
	flex: 1 0 auto; display: flex; flex-direction: column;

	&.small { min-height: 300px; }
	&.standalone {
		@include defaultMargin(); border-radius: $base-radius;
	}

	H2, H3, H4, H5, H6 { font-weight: $medium; margin: 0; color: inherit; font-size: 48px; line-height: 52px; }
	SUP { font-size: 30%; vertical-align: 0; transform: translate(0, -20%); position: absolute; }

	.pre-heading { margin: 0; font-size: 24px; font-weight: $medium; }
	.has-image { color: rgba(0, 0, 0, 0) !important; background-size: contain; background-position: 50% 50%; background-repeat: no-repeat; }

	@include mobile {
		H2, H3, H4, H5, H6 { font-size: 36px; line-height: 42px; }
		.pre-heading { font-size: 18px; }
	}

	UL:not([class]) {
		padding: 0; margin: $gridunit 0 0 0;
		> LI {
			padding: 0; margin: 0;
			&:before { display: none; }
			+ LI {
				padding-top: 20px;
				&:before {
					display: block; width: 10px; height: 1px; left: 50%; top: 10px; border-radius: 0; transform: translate(-50%, 0); opacity: 0.2;
				}
			}
		}
	}

	&__text {
		flex: 0 0 auto; padding: 50px;
		@include mobile {
			padding: $gridunit;
		}
	}
	&__image {
		flex: 1 1 auto; margin: 0 50px 50px 50px;
		@include mobile {
			margin: 0 $gridunit $gridunit $gridunit;
		}
		background-size: contain; background-position: 50% 50%; background-repeat: no-repeat;
		&.no-margin { margin: 0 !important; }
	}

	&__actions {
		text-align: center; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		&.vertical { flex-direction: column; }
		A {
			padding: 8px 16px; line-height: 20px; border-radius: 18px; position: relative;
			&:not(.primary) {
				&:after { content: ""; position: absolute; left: 100%; top: 50%; width: 0.5em; height: 0.5em; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform: translate(-150%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); transform-origin: 50% 50%; }
			}
			&.primary {
				background-color: $color-link; color: $color-white !important;
				&:hover { background-color: $color-link-hover; text-decoration: none; }
			}
		}
	}

	// Wide size

	@at-root .layout-grid__container.wide & {
		@include not-mobile {
			flex-direction: row; flex-wrap: nowrap;
			&__text { flex: 0 0 450px; align-self: center; }
			&__image { flex: 1 1 auto; margin: 50px 50px 50px 0; }

			&.reversed { flex-direction: row-reverse; }
			&.reversed .box-promo__image { margin: 50px 0 50px 50px; }
		}
	}

	// On gray background

	@at-root .gray & {
		background-color: $color-white;
	}

	// Alignment

	&.align-horizontal-left {
		text-align: left;
	}
	&.align-vertical-center {
		align-items: center; justify-content: center;
	}

	// Text themes

	&.text-white {
		color: $color-white;
		A { color: #2997ff; }
	}
	&.text-white &__actions {
		A {
			color: $color07;
			&.primary { background-color: $color07; }
		}
	}

	// Action themes

	&.actions-white &__actions {
		A {
			color: $color-white;
			&.primary { background-color: $color-white; color: $color07 !important; }
		}
	}
}



// Product listing toolbar

.product-toolbar {
	$h: 30px;
	display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; padding: 20px 0 20px 20px; border-bottom: 1px solid $color-gray-lighter; font-size: $font-size-small;

	@include mobile {
		margin: 0 $gridunit * -1; padding: 10px $gridunit;
	}
	.group { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }
	.label {
		display: block; padding: 5px 0; margin: 0 7px 0 0; opacity: 0.5;
		@include mobile { display: none; }
	}

	.icon {
		flex: 0 0 auto; display: block; width: 12px; height: 12px; overflow: hidden; margin: 0 7px 0 0; opacity: 0.3; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
		IMG { width: 100%; height: auto; float: left; transform: translate(0, -50%); }
	}

	LABEL { flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; }

	SELECT {
		border: 0 none; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); font-size: inherit; appearance: none; outline: none;
		padding: 0 30px 0 10px; height: $h; line-height: $h; border-radius: $base-radius; margin: 0 7px 0 0;
		background: $color-white url('img/select-arrow.png') 100% 50% no-repeat; background-size: auto 16px;
		&::-ms-expand {
			display: none;
		}
	}
	.togglebutton {
		display: flex; width: $h; height: $h; position: relative; z-index: 0;
		A {
			display: block; width: $h; height: $h; position: absolute; left: 0; top: 0; z-index: 0; opacity: 0;
			box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); border-radius: $base-radius;
			&.active { z-index: 2; opacity: 1; pointer-events: none; }
		}
		&:hover A {
			.icon { opacity: 1; }}
	}

	.pillbutton {
		position: relative; z-index: 0; display: flex; flex-direction: row; flex-wrap: nowrap;
		border-radius: $base-radius; overflow: hidden;
		&:before { content: ""; pointer-events: none; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px rgba($color-black, 0.1); border-radius: $base-radius; z-index: 2; }
		A {
			flex: 0 0 auto; display: block; width: $h; height: $h; position: relative;
			&.active {
				.icon { opacity: 0.7; }
			}
			&:not(:last-child) { border-right: 1px solid $color-gray-light; }
			&:hover {
				.icon { opacity: 1; }
			}
		}
	}
}

// Longpage wrapper

.longpage { overflow: hidden; flex: 1 0 auto; }

// Utilities

.content {
	line-height: 1.5; max-width: 100%;
	overflow: hidden;
	> H2 { text-transform: uppercase; font-size: $base-font-size; margin: 1.5em 0 0; font-weight: $bold; }
	&__header {
		@include desktop { display: flex; align-items: baseline; justify-content: space-between; }
		H1,
		H2 {
			margin: 0; font-size: $font-size-larger02; font-weight: $bold; flex: 0 0 auto; max-width: 100%;
			@include not-desktop { font-size: $font-size-large; }
			.tag {
				margin-left: $gridunit-small;
				@include not-mobile {
					position: relative; top: -0.5em;
				}
			}
		}
		+ * { margin: $gridunit 0 0; }
		.breadcrumb + & { margin: $gridunit*2 0 0; }
	}
	& + & { margin: $gridunit-large 0 0; }
	IMG { max-width: 100%; height: auto; }
	A:not(.button) { text-decoration: underline; }
	TABLE {
		max-width: 100%; border: 1px solid $color-gray-light;
		TD, TH { white-space: normal; border: 1px solid $color-gray-light; padding: $gridunit-small !important; }
	}
}

.longtext {
	font-size: 17px; line-height: 1.5;
	P, HR { @include defaultMargin(); }
}

.cat-label { color: #f56300; font-size: 11px; margin-left: 5px; vertical-align: 5%; }

.pre-title {
	color: #f56300; font-size: 15px; line-height: 18px; display: block; font-weight: 400; margin-top: -18px;
	@include mobile { font-size: 11px; }
}

.text-accented { color: $brand-primary-800-wcag; }

// Paginator

.paginator {
	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: center; font-size: 16px; color: $color-text-dark; @include defaultMargin();
	INPUT {
		outline: none; width: 34px; height: 34px; border: none; box-shadow: inset 0 0 0 1px $color-gray-light; border-radius: 5px; text-align: center; color: $color-text-dark; padding: 0; margin: 0 1ex 0 0; font-size: 16px; line-height: 34px; box-sizing: border-box;
		&:focus {
			box-shadow: inset 0 0 0 1px $color-gray-medium, 0 0 0 3px rgba($color-link, 0.2); color: $color-text-dark;
		}
	}
	&__counter {
		display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center; margin: 0 50px;
	}
	A {
		width: 36px; height: 36px; text-decoration: none; border-radius: 50%; overflow: hidden; display: block; transition: all $uispeed $uieasing 0ms; position: relative; color: inherit;
		&:hover { background-color: $color-gray-lightest; }
		SPAN { display: none; }
		&:before { content: ""; position: absolute; left: 50%; top: 50%; width: 14px; height: 14px; box-sizing: border-box; border: solid currentColor; border-width: 0.15em 0.15em 0 0; transform-origin: 50% 50%; }
	}
	&__prev:before { transform: translate(-40%, -50%) scaleX(0.85) rotate(-135deg) scale(0.8); }
	&__next:before { transform: translate(-60%, -50%) scaleX(0.85) rotate(45deg) scale(0.8); }
}

// Generic callout

.generic-callout {
	padding: 0 $spacer-desktop; position: relative; color: $color-gray-dark;

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit / 2 0; text-align: center; font-size: $font-size-small;
		P { margin: 0; }
	}

	background-color: $color-gray-lighter;

	&.intent-danger {
		color: $color-text-dark; background: $color-system-danger-200;
		A { color: $color-text-dark; text-decoration: underline; }
	}
	&.intent-warning {
		color: $color-text-dark; background: $color-system-warn-200;
		A { color: $color-text-dark; text-decoration: underline; }
	}
	&.intent-success {
		color: $color-text-dark; background: $color-system-success-200;
		A { color: $color-text-dark; text-decoration: underline; }
	}
	&.intent-promotion {
		color: $color-text-dark; background: $brand-primary-200;
		A { color: $color-text-dark; text-decoration: underline; }
	}

	& + & {
		&:before { content: ""; position: absolute; left: 0; top: 0; right: 0; height: 1px; background-color: currentColor; opacity: 0.1; }
	}
}

// Generic message

.generic-message {
	flex: 1 0 auto; text-align: center; font-size: $font-size-medium; line-height: 1.5;
	&__limiter {
		width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit-large;
		P { margin: 0; }
	}
	&__title { font-size: $font-size-large; font-weight: $bold; }
}

// Footnote

.footnote {
	font-size: $font-size-small; line-height: 1.5;
}

//

.generic-actions {
	display: flex; flex-direction: row; flex-wrap: wrap; margin: 10px -12px 0 -12px;
	> * {
		margin: 10px 12px; position: relative;
		&:not(:first-child):before { content: "|"; position: absolute; left: -12px; top: 50%; color: $color-gray-light; pointer-events: none; transform: translate(-50%, -50%); }
	}
	.intent-danger { color: $color-danger; }
}

// Cookie message

.cookiemessage {
	background-color: $color-gray-lightest; color: $color-gray-dark; border-top: 12px solid $color-white;
	position: sticky; bottom: 0; padding: 0 $spacer-desktop; z-index: 16000003; // zopim + 1

	@include mobile { padding: 0 $spacer-mobile; }
	@include tablet { padding: 0 $spacer-tablet; }

	&__limiter { width: 100%; max-width: $content-maxwidth; margin: auto; box-sizing: border-box; padding: $gridunit 0; text-align: center; font-size: $font-size-smaller; }

	.actions {
		margin-top: 10px; display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: center;
		A {
			flex: 0 0 auto; background-color: $color-link; color: $color-white; line-height: 24px; padding: 0 16px; border-radius: 12px; margin: 3px;
			&:hover { text-decoration: none; background-color: $color-link-hover; }
		}
	}
}

// Embed video

.embed-video {
	@include defaultMargin();
	position: relative; padding-bottom: 56.25%; height: 0;
}
.embed-video IFRAME {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// Apple pay button

@supports (-webkit-appearance: -apple-pay-button) {
	.apple-pay-button { display: block; @include defaultMargin(); -webkit-appearance: -apple-pay-button; -apple-pay-button-type: buy; }
	.apple-pay-button-black { -apple-pay-button-style: black; }
	.apple-pay-button-white { -apple-pay-button-style: white; }
	.apple-pay-button-white-with-line { -apple-pay-button-style: white-outline; }
}
@supports not (-webkit-appearance: -apple-pay-button) {
	.apple-pay-wrapper { display: none; }
}

// Bulletlists

.list {
	&-check-bullets {
		margin: $gridunit 0 0; line-height: 1.5;
		> LI {
			position: relative; padding-left: $gridunit-large; margin: $gridunit 0;
			&:before { content: ""; background-color: $brand-primary-800; position: absolute; left: 0; top: .2em; width: $gridunit; height: $gridunit; border-radius: 50%; }
			&:after { content: ""; background-color: transparent; position: absolute; box-sizing: border-box; left: 5px; top: .3em; border: .175em $color-text-light; border-style: none solid solid none; width: 5px; height: 10px; transform: rotate(45deg)}
		}
	}
	&-icons {
		padding: 0; margin: 0; list-style: none; counter-reset: steps;
		> LI {
			margin: $gridunit 0 0 100px; padding-left: $gridunit;  position: relative; min-height: 100px; display: flex; align-items: center;
		}
		&__content {
			display: flex;
			&:before { counter-increment: steps; content: counter(steps) "."; margin-right: $gridunit-small; font-weight: $bold; }
		}
		&__illustration {
			position: absolute; left: -100px; top: 50%; transform: translateY(-50%); height: 100px; width: 100px; border-radius: 50%; background: $brand-accent-2-100;
			display: flex; justify-content: center; align-items: center;
		}
		@include mobile {
			> LI { margin-left: 70px; min-height: 70px; }
			&__illustration {
				width: 70px; height: 70px; left: -70px;
				IMG { max-height: 35px; max-width: 35px; height: auto; width: auto; }
			}
		}
	}

}


// Badges

.tag {
	font-size: $font-size-small; font-weight: $normal; background-color: $brand-gray-200; text-transform: uppercase; border-radius: $base-radius-large; padding: $gridunit-small/2 $gridunit-small;
	&.success { background-color: $color-system-success-200; color: $color-system-success-800; }
	&.danger { background-color: $color-system-danger-200; color: $color-system-danger-800; }
	&.warn { background-color: $color-system-warn-200; color: $color-system-warn-800; }
	&.info { background-color: $color-system-info-200; color: $color-system-info-1000; }
}

.stores-map {
	width: 100%; display: flex; flex-direction: column;
	&__inner { flex: 1; position: relative; overflow: hidden; }
	@include mobile {
		height: 440px; margin: $gridunit $gridunit*-1 0; width: auto;
	}
}

.store-map {
	margin-top: $gridunit;
	@include mobile { margin: $gridunit $gridunit*-1 0; }
}

.location-cards {
	display: flex; flex-wrap: wrap; margin: $gridunit $gridunit-small/-2 0;
	&.size-small {
		margin: $gridunit $gridunit/-2 0;
		@at-root &__item { flex: 0 0 330px; margin: $gridunit/2; }
	}
	&.size-narrow { max-width: 740px; }
	&__item {
		border: 1px solid $brand-gray-200; border-radius: $base-radius; padding: $gridunit; box-sizing: border-box; margin: $gridunit-small/2; flex: 0 0 336px; line-height: 1.3;
		&.noborder {
			border: none; display: flex; flex-direction: column; justify-content: stretch; padding: 0; margin: $gridunit-small $gridunit-small/2;
			A:not([href^="tel:"]) {
				flex: 0 0 100%; text-transform: uppercase; font-weight: $bold; text-decoration: none; color: $color-text-dark; border-radius: $base-radius; border: 1px solid $brand-primary-800; padding: $gridunit; box-sizing: border-box; display: flex; justify-content: center; align-items: center;
				&:hover { border-color: $brand-primary-800; color: $brand-primary-800-wcag; }
				&:focus { box-shadow: $shadow-focus2; }
			}
		}
		> P:first-child {
			margin: 0;
			+ * { margin: $gridunit 0 0; }
		}
	}
	&__title {
		margin: 0; font-size: $base-font-size; font-weight: $bold; text-transform: uppercase;
		+ P { margin: 0; }
	}
	A {
		text-decoration: underline;
		&:hover { }
	}
	@include mobile {
		&__item {
			flex: 0 0 100%; margin: $gridunit-small/2 0;
			&.noborder { margin: $gridunit-small/2 0; }
		}
	}
}

.content-card {
	border: 1px solid $brand-gray-200; border-radius: $base-radius; padding: $gridunit; box-sizing: border-box; margin: $gridunit 0 0; line-height: 1.3;
	> *:first-child { margin-top: 0; }
	A {
		text-decoration: underline;
		&:hover { }
	}
	&.size-small { max-width: 330px; }
	&.dark { background: $brand-gray-200; }
	&.inline { display: inline-block; }
	P {
		&.has-icon {
			position: relative; padding-left: 24px;
			.ico { position: absolute; left: 0; top: 0; max-width: 18px; }
		}
	}
	.frame-ebeauty & .buttons { margin-left: 0; }
}
.content-cards {
	@include not-mobile {
		display: flex; flex-wrap: wrap;
		.content-card { margin: $gridunit $gridunit 0 0; max-width: 300px; }
	}
}

.heading-with-buttons {
	H2 { font-weight: $bold; }
}

.shortcuts {
	@include not-mobile { display: none; }
	background-color: $color-white; padding: 0 $gridunit-small $gridunit;
	UL { display: flex; flex-wrap: wrap; justify-content: center; }
	LI { width: 33.333%; font-size: $font-size-small; margin: $gridunit 0 0; }
	A {
		color: $color-text-dark; text-align: center; text-decoration: none !important; display: flex; flex-direction: column; justify-content: center; align-items: center;
		&:hover { color: $brand-primary-800-wcag; }
	}
	&__item-icon {
		width: 64px; height: 64px; display: flex; justify-content: center; align-items: center;
		IMG { max-width: 40px; max-height: 40px; height: auto; width: auto; }
	}
	&__item-inner { position: absolute; left: 0; top: 0; bottom: 0; right: 0; display: flex; flex-direction: column; justify-content: center; align-items: center; }
}

// Content images

.img-round {
	background-color: $brand-accent-100; border-radius: 50%; overflow: hidden; max-width: 250px; width: 250px; height: 250px; display: flex; justify-content: center; align-items: center;
	IMG { max-width: 100%; max-height: 100%; height: auto; width: auto; }
	@include tablet { width: 125px; height: 125px; max-width: 125px; }
	@include mobile { align-self: center; }
}

.img-right {
	@include not-mobile { float: right; margin: 0 0 $gridunit $gridunit; }
}

.img-left {
	@include not-mobile { float: left; margin: 0 $gridunit $gridunit 0; }
}

// Social links

.social-links {
	display: flex; flex-direction: row; flex-wrap: wrap;
	> LI {
		margin: $gridunit $gridunit 0 0;
		A {
			display: flex; align-items: center; justify-content: center; width: 36px; height: 36px; border-radius: 50%; background-color: rgba($color-black, 0.1);
			&:hover { background-color: rgba($color-black, 0.3); }
		}
	}
	svg {
		width: 36px; height: 36px;
		path { fill: $brand-primary-800-wcag; }
	}
}

// Basic carousel

.carousel-items { display: flex; overflow: hidden; }

.feature-blocks {
	display: flex; flex-wrap: wrap; line-height: 1.5; margin: $gridunit-large 0 0;
	&__item {
		flex: 0 0 calc(33.333% - #{$gridunit}); margin: $gridunit/2; box-sizing: border-box;
		text-align: center; background-color: $color-white;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
		@include mobile { flex: 0 0 100%; margin: $gridunit/2 0; }
		> A {
			position: absolute; text-indent: -10000; left: 0; right: 0; top: 0; bottom: 0;
			&:hover { color: $brand-accent-wcag; }
		}
	}
	&__inner { display: flex; flex-direction: column; color: $color-text-dark; text-decoration: none;}
	&__text { margin: 0; padding: $gridunit; }
	&__image {
		position: relative; padding-bottom: 100%; overflow: hidden;
		IMG { position: absolute; left: 0; top: 0; right: 0; bottom: 0; object-fit: cover; object-position: center; }
	}
	H2 { margin: 0; }
}
.category-blocks {
	display: flex; flex-wrap: wrap; line-height: 1.5; margin: $gridunit-large 0 0;
	&__item {
		flex: 0 0 calc(33.333% - #{$gridunit}); margin: $gridunit/2; box-sizing: border-box;
		text-align: center; background-color: $color-white;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
		@include mobile { flex: 0 0 100%; margin: $gridunit/2 0; }
		A {
			display: flex; flex-direction: column; color: $color-text-dark; text-decoration: none;
			&:hover { color: $brand-accent-wcag; }
		}
	}
	&__text { margin: 0; padding: $gridunit; font-weight: $bold; }
	&__image {
		position: relative; padding-bottom: 100%; overflow: hidden;
		IMG { position: absolute; left: 0; top: 0; right: 0; bottom: 0; object-fit: cover; object-position: center; }
	}
}

// Plain unknown html from external source

.plain-html {
	@include clearfix; overflow: hidden; overflow-x: auto;

	UL {
		margin: 1em 0 0 0; padding: 0 0 0 2em;
		> LI {
			display: block; padding: 0 0 0 1.5em; margin: 0; position: relative;
			&:before,
			&:after { content: ""; position: absolute; left: 0; top: 0.65em; width: 0.45em; height: 0.45em; background-color: currentColor; border-radius: 50%; }
			&:after { display: none; }
			+ LI { margin-top: 0.5em; }
		}
	}

	P:empty { display: none; }
	TABLE {
		border: 1px solid $color-gray-light; width: 100%; border-collapse: collapse;
		TH, TD {
			border: 1px solid $color-gray-light; text-align: left; vertical-align: top; white-space: normal; padding: $gridunit / 2 $gridunit !important;
			> P:first-child { margin-top: 0; }
		}

		*[width] { width: auto !important; }
		*[height] { width: auto !important; }
	}
	TABLE:not([class]) {

	}
}

INPUT.toggle-next-mobile {
	@include not-mobile { display: none; }
	@include mobile {
		position: absolute; left: -10000px;
		+ * {
			border-bottom: 1px solid transparentize($brand-primary-800,.8); padding-bottom: $gridunit-small;
			LABEL:before { content: "+"; width: 1em; text-align: center; display: inline-block; color: $brand-primary-600; font-weight: $normal; font-size: $font-size-medium-large; margin-right: $gridunit-small; }
			+ * { display: none; }
		}
		&:checked {
			+ * {
				border-bottom: none; padding-bottom: 0;
				LABEL:before { content: "-"; }
				+ * { display: block; padding-bottom: $gridunit-small; padding-left: calc(1em + #{$gridunit-small}); border-bottom: 1px solid transparentize($brand-primary-800,.8);}
			}
		}
	}
}

.photogrid {
	display: flex; flex-wrap: wrap;
	&__item {
		A, IMG { display: block; width: 100%; }
		A { position: relative; padding-bottom: 100%; }
		IMG { position: absolute; left: 0; top: 0; height: 100%; object-fit: cover; }
		position: relative; flex: 0 0 50%;
		@include desktop { flex: 0 0 16.666%; }
		@include desktop-wide { flex: 0 0 16.666%; }
		@include tablet {
			flex: 0 0 20%;
			&:nth-child(10),
			&:nth-child(11) { display: none; }
		}
		@include mobile-wide { flex: 0 0 33.333%; }
		&:hover .photogrid__item-overlay { opacity: 1; }
		&-overlay {
			transition: opacity $uispeed $uieasing; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: rgba(0,0,0,.6); color: $color-text-light; display: flex; justify-content: center; align-items: center; padding: $gridunit; pointer-events: none; opacity: 0;
		}
	}
}

.category-view {
	margin-top: $gridunit;
	.category-description { line-height: 1.5; }
	@include not-mobile {
		overflow: hidden;
		.category-image {
			float: right; max-width: 50%; margin-left: $gridunit;
			IMG { max-width: 100%; max-height: 250px;}
		}
		.category-cms { clear: both; }
	};
	@include mobile {
		.category-image {
			IMG { max-width: 100%; }
		}
	}
}

.brands {
	display: grid; grid-column-gap: $gridunit; grid-row-gap: $gridunit; padding-bottom: $gridunit-large;
	@include desktop { grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }
	@include desktop-narrow { grid-template-columns: 1fr 1fr 1fr 1fr; }
	@include tablet { grid-template-columns: 1fr 1fr 1fr; }
	@include mobile { grid-template-columns: 1fr; }
	@include mobile-wide { grid-template-columns: 1fr 1fr; }

	H2 { border-bottom: 1px solid $brand-gray-200; color: $brand-primary-800-wcag; font-size: $font-size-medium-large; }
	UL {
		LI {
			margin: 0; padding: 0; font-size: $font-size-small;
			&:before { display: none; }
			A {
				display: block; padding: $gridunit-small $gridunit-small; text-decoration: none; text-transform: uppercase; color: $color-text-dark;
				&:hover { background-color: transparentize($brand-accent-4, .3); }
			}
		}
	}
}
