// Products layout

.layout-products {
  &__list {
    flex: 1 0 auto; @include defaultMargin(12px);

    @include mobile { margin: 0 -20px; }
    @include not-mobile { display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 12px; margin: 0 -12px 0 -12px; }

    &.light {
      display: flex; flex-direction: row; flex-wrap: wrap; padding: $gridunit-small/2; justify-content: flex-start;

      @include mobile {
        margin: 0 -20px 0 -21px;
      }
      @include not-mobile {
        margin: 0; overflow: hidden;
      }
    }

    &.list { display: block; }

    &.standalone { margin-top: 18px; }
  }

  &__container {
    $product-card-width: 275px;

    min-width: $product-card-width; box-sizing: border-box; padding: $gridunit-small/2; display: flex; flex-direction: column;
    .box-product { margin: 0 auto; }
    .layout-product__row & {
      @include desktop {
        min-width: 50%;
        .box-product { max-width: 100%; }
      }
    }
    @include mobile {
      padding: 12px 0 0 0; flex: 0 0 50%; min-width: 0; max-width: 50%;
    }
    @include mobile-small {
      flex: 0 0 100%; min-width: 0; max-width: none;
    }
    @media only screen and (min-width: $product-card-width*2 + $gridunit*2) {
      flex: 0 0 50%;
    }
    @media only screen and (min-width: $product-card-width*3 + $gridunit*2) {
      flex: 0 0 33.333%;
    }
    @media only screen and (min-width: $product-card-width*4 + $gridunit*2)  {
      flex: 0 0 25%;
    }
  }

  /*&__list.light &__container {
    padding: 0;
    @include mobile {
      flex: 0 0 50%; max-width: 50%; padding: 0;
    }
    @include not-desktop-wide {
      flex: 0 0 50%; max-width: 50%;
    }
  }*/

  &__list.list &__container { max-width: 100%; padding: 0; }
  &-compact {
    .checkboxes & {
      flex: 0 0 100%; display: flex; flex-direction: column;
      > * { flex: 1; }
    }
    .box-product {
      flex-direction: row; max-width: none; padding: 0; width: auto;

      &.selectable {
        border: 1px solid $brand-gray-placeholder; border-radius: $base-radius; padding: $gridunit-small;
        @include mobile {
          flex-direction: row-reverse;
          .box-product {
            &__main { margin-left: $gridunit; }
            &__text { text-align: center; }
          }
        }
      }
      &.selected { border-color: $brand-accent-100; background-color: $brand-accent-100; }
      .control-checkbox,
      .control-radio { align-self: center; margin-right: $gridunit; }
      &__title { margin-top: 0; font-weight: $bold; }
      &__description { margin-top: $gridunit-small; }
      &__main { flex: 0 1 100%; display: flex; flex-direction: row; }
      &__actions { flex: 0 0 auto; }
      &__image { flex: 0 0 80px; width: 80px; align-self: flex-start; min-height: 0; height: 80px; border-radius: $base-radius; overflow: hidden; }
      &__content { padding: $gridunit $gridunit-small; }
      @include mobile {
        &__main { flex-direction: column; }
        &__content {padding: $gridunit 0; }
        &__prices { margin: 0;}
        &__image {align-self: center; }
        .control-checkbox,
        .control-radio { align-self: flex-start; margin-top: $gridunit; }
      }
    }
    .product-pricing__price-number {font-size: $base-font-size; }
  }
}
