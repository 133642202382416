.system-notifications {
  &.separated { margin-top: $gridunit-large; }
  &.sticky { position: sticky; }
}
.system {
  &-notification {
    display: flex; align-items: flex-start; border-color: $brand-gray-300; background-color: $brand-gray-300; padding: $gridunit-small $gridunit-small $gridunit;
    &__icon {
      margin: $gridunit-small $gridunit $gridunit-small $gridunit-small; width: 30px; height: 30px; flex: 0 0 auto;
      &.size-small { width: $gridunit; height: $gridunit; margin-top: 9px; }
      &.size-large { margin: $gridunit-small/2 $gridunit 0 $gridunit-small; }
      IMG { width: 100%; height: 100%; }
      svg {
        width: 100%; height: 100%;
        .danger & path { fill: $color-system-danger-800; }
        .info & path { fill: $color-system-info-1000; }
        .warn & path { fill: $color-system-warn-650; }
        .success & path { fill: $color-system-success-800; }
      }
      //&:not(.size-small) + .system-notification__content > *:first-child:not(.system-notification__title) { margin-top: $gridunit-small !important; }
    }
    &__content {
      flex: 0 1 100%; padding: $gridunit-small 0; line-height: 1.4; color: $color-text-dark;
      > *:first-child:not(.system-notification__title) { margin-top: 0; }
      A { color: $color-link; font-weight: $normal; text-decoration: underline; }
      IMG { position: relative; top: .2em; margin-right: $gridunit-small; }
      .full & { flex: 0 1 auto; margin-left: 0 !important; }
    }
    &__close {
      cursor: pointer; flex: 0 0 auto; padding: $gridunit-small $gridunit; border-radius: $base-radius; border: 1px solid $brand-gray-350; background: $color-white; display: flex; align-items: center; color: $color-text-dark;
      IMG,
      svg { margin-right: $gridunit-small; width: 10px; height: 10px; }
      &:hover { border-color: $color-system-danger-800; color: $color-system-danger-800; text-decoration: none; }
      &:active { background: $brand-gray-200; }
      &:focus { box-shadow: $shadow-focus2; }
    }
    &__title { font-size: $base-font-size; font-weight: $bold; margin: $gridunit-small 0 0; }
    .inline & { margin: $gridunit 0 0; border: 1px solid; padding: $gridunit-small $gridunit; border-radius: $base-radius; }
    .minimal & { padding: 0 $gridunit-small; border-radius: $base-radius; }
    .full & { justify-content: center; padding: $gridunit-small/2 $gridunit-small; }
    &.info {
      border-color: $color-system-info-350; background-color: $color-system-info-200;
      .system-notification__icon svg path { fill: $color-system-info-1000; }
    }
    &.danger { border-color: $color-system-danger-300; background-color: $color-system-danger-200; }
    &.warn { border-color: $color-system-warn-800; background-color: $color-system-warn-500; }
    &.success { border-color: $color-system-success-250; background-color: $color-system-success-200; }

    &.discrete { border-color: transparent; background-color: transparent; padding: 0; }

    @include mobile {
      &__icon { width: $gridunit-large; height: $gridunit-large; margin: $gridunit-small; }
      &__content:not(:first-child) { margin-left: -($gridunit-large+$gridunit-small); padding-left: 0; }
      &__title { padding-left: $gridunit-large+$gridunit; margin-top: $gridunit-small/2; }
      .mobile-compact & {
        .system-notification__content:not(:first-child) { margin-left: 0; padding-left: 0; }
      }
    }
  }
}

#reserveTimer .time-remaining {
  border-color: $color-system-info-350; background-color: $color-system-info-200; font-size: $base-font-size;
  P:first-child { margin: 0; }
  B { color: $brand-primary-900 !important; }
}

.notification {
  &-mobileid {
    display: inline-block; margin: $gridunit 0 0; background: $brand-gray-200; padding: $gridunit $gridunit-large;
    STRONG { font-size: $font-size-larger; position: relative; top: .1em; }
  }
}

.mobile-notifications {
  @include not-mobile { display: none; }
  @include mobile { position: fixed; left: 0; right: 0; bottom: 0; z-index: 200; }
}

.#{$ns}-toast {
  .toast-content {
    &.intent-danger .toast-content-text { color: $color-text-dark !important; }
  }
}
