.frame-checkout {
  .layout-sidebar {
    margin: $gridunit 0 0;
    &-secondary {
      background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius; padding: $gridunit; box-sizing: border-box; margin-top: 40px;
      .heading-with-buttons {
        margin: 0;
      }
      @include not-mobile {
        align-self: flex-start; margin-left: $gridunit*2;
      }
    }
  }
  &-nav {
    overflow: visible !important; z-index: 3 !important;
    @include mobile {
      .logo IMG { max-width: 120px; height: auto; }
    }
  }
  &__sidebar-icon {
    width: 72px; height: 72px; border-radius: 50%; position: absolute; right: $gridunit-small/2; top: -36px;
    background-color: $color-white; display: flex; justify-content: center; align-items: center;
    box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16);
  }
  .list-progress {
    &-item {
      border: 1px solid $brand-gray-200; border-radius: $base-radius; padding: $gridunit; margin: $gridunit-small 0 0;
      .list-progress-item-title {
        background-color: transparent !important; font-size: $font-size-larger; font-weight: $bold;
        &:before { border-radius: 50%; font-size: 30px; background-color: $brand-gray-200; }
      }
      &.current {
        margin-bottom: $gridunit-large;
        .list-progress-item-title {
          color: $brand-primary-800-wcag;
          &:before { background-color: $brand-gray-200; color: $brand-primary-800-wcag; }
        }
      }
      &-content {
        padding-left: $gridunit;
        @include mobile { padding-left: $gridunit-small; }
      }
    }
  }
  .menu-layout-inline {
    LI + LI {margin-top: $gridunit-small/2; }
    .menu-item {
      background-color: transparent !important; border: 1px solid $brand-gray-200; border-radius: $base-radius; text-decoration: none;
      &.active { box-shadow: none !important; background-color: $brand-accent-100 !important; }
      &:hover { color: $brand-primary-800-wcag !important; }
    }
    .bp3-menu-item-label STRONG { color: $brand-primary-800-wcag; }
  }
  .selectable-item,
  .selectable-button { padding: $gridunit; }
  .selectable {
    margin-left: -$gridunit;
    BUTTON {
      border: 1px solid $brand-gray-200; color: $color-text-dark;
      &.active {
        background-color: $brand-gray-200;
        &:before { display: none; }
        .selectable-item-marker { padding: $gridunit-small/2 0; background-color: transparent; }
      }
      .selectable-item {
        &-title { font-size: $base-font-size; padding-bottom: $gridunit-small; }
        &-marker { align-self: flex-start; border-radius: $base-radius; font-size: $base-font-size; text-transform: none; font-weight: $normal; margin: $gridunit 0 0; background: $brand-primary-800-wcag; padding: $gridunit-small/2 $gridunit; color: $color-text-light; }
        &-inner { padding: $gridunit-small; }
      }
    }
  }
  .button.layout-default {
    &.intent-default {
      &:before { display: none; }
      background-color: $brand-primary-800; color: $color-text-light; border: 1px solid $brand-primary-800; font-weight: $medium; box-shadow: none;
      &,
      &:hover,
      &:active,
      &:focus {
        .text,
        .icon { color: inherit; }
      }
      &:hover { background-color: $brand-primary-900; border-color: $brand-primary-900; }
      &:active { background-color: $brand-primary-900; border-color: $brand-primary-600; }
      &:focus { box-shadow: $shadow-focus2; }
    }
  }

  .userhub {
    flex: 0 0 auto;
    @include not-desktop-wide { position: relative; top: -$gridunit-small/2; }
    .link-search { display: none; }
    .link-account {
      A.toggler {
        padding: $gridunit-small/2 $gridunit-small; border-radius: $base-radius; position: relative; min-width: 130px; justify-content: flex-start;
        &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 1px solid $color-header-accent;  border-radius: $base-radius; }
        .label {
          display: block;
          SPAN {
            display: block;
            &.account {
              &-title { font-size: $font-size-smaller; font-weight: $bold; letter-spacing: 0.01em; }
              &-subtitle { text-transform: uppercase; letter-spacing: 0.001em; line-height: 0.875; }
            }
          }
        }
        &:hover,
        .visible-focus &:focus,
        &:active {
          &:after {
            content: ""; position: absolute; left: -2px; right: -2px; top: -2px; bottom: -2px; border: 3px solid $color-header-accent; border-radius: $base-radius+2;
          }
        }
        &:hover {
          background: $color-background-crossportal-hover;
        }
        &:active,
        .visible-focus &:focus {
          background: $color-background-crossportal-active;
        }
        .icon { width: 18px; height: 18px; border-radius: $base-radius; overflow: hidden; }
      }
      .#{$ns} {
        &-popover-open > A {
          background: $color-white; color: $color-text-dark; border-radius: $base-radius $base-radius 0 0;
          &:before { border-style: solid solid none solid !important; border-radius: $base-radius $base-radius 0 0 !important; }
          &:after { content: ""; position: absolute; left: -2px; right: -2px; top: -2px; bottom: -2px; border: 3px $color-header-accent; border-style: solid solid none solid !important; border-radius: $base-radius-medium $base-radius-medium 0 0 !important; }
          &:hover,
          .visible-focus &:focus,
          &:active { background: $color-white; }
        }
        &-transition-container {
          width: 100%;
          UL {
            background: $color-white; border-radius: 0 0 $base-radius $base-radius; padding: $gridunit-large 0; position: relative;
            &:before {
              content: "";
              position: absolute; top: 0; left: -2px; right: -2px; bottom: -2px; border: 3px $color-header-accent; border-style: none solid solid solid; border-radius: 0 0 $base-radius-medium $base-radius-medium;
            }
          }
          LI { margin-left: 0; }
          A {
            border: none; padding: $gridunit-small $gridunit; text-align: left; display: block; text-transform: none; font-weight: $bold; color: $color-text-dark;
            &:hover { background: $color-background-crossportal-hover; }
            &:active,
            .visible-focus &:focus { background: $color-background-crossportal-active; }
            &.button { margin: $gridunit $gridunit 0; border-radius: $base-radius; text-align: center; white-space: normal; background: $brand-gray-300; font-size: $base-font-size; }
          }
        }
      }
    }
    .label { display: none; }
    A {
      .icon { float: left; width: $gridunit-large; height: $gridunit-large; }
    }
  }
  .time-remaining {
    border-color: $color-system-info-350; background-color: $color-system-info-200; font-size: $base-font-size;
    P:first-child { margin: 0; }
    B { color: $brand-primary-900 !important; }
  }
  .banklinks.layout-vertical {
    display: grid; grid-column-gap: $gridunit; grid-row-gap: $gridunit; max-width: none; padding: 0 $gridunit-large*1.7 0 $gridunit-large; margin-top: $gridunit-small*1.5; box-sizing: border-box;
    @include not-mobile { grid-template-columns: 1fr 1fr; }
    @include mobile { padding: 0 0 0 $gridunit; }
    > LI {
      border: 1px solid $brand-gray-350; border-radius: $base-radius; margin: 0 !important; padding: $gridunit-small/2 $gridunit-small/2 $gridunit-small/2 $gridunit-small;
      flex-direction: row-reverse !important; gap: $gridunit;
      .banklinks-item-description { padding-left: 0; font-size: $font-size-medium-small; font-weight: $bold; }
      BUTTON {
        padding: 0; align-self: center; border: none;
        &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: none; width: auto; height: auto; background-color: transparent; }
        > *,
        .banklinks-item-image {
          width: 77px; height: 26px; position: static !important; display: flex; justify-content: center; align-items: center;
          + .banklinks-item-label { text-indent: -10000px; }
        }
      }
      &:hover { background-color: rgba($brand-primary-200, 0.2); }
    }
  }
}
.global-frame-checkout {
  .summary {
    margin: 0;
    .summary-title { background-color: transparent; padding: $gridunit 0 $gridunit-small; font-size: $base-font-size; font-weight: $bold; }
    .summary-list {
      .summary-list-item {
        background-color: transparent; border: 2px solid $brand-gray-200; border-radius: $base-radius; margin: $gridunit-small/2 0 0; padding: $gridunit-small;
        .name { font-size: $base-font-size; }
      }
    }
    .summary-totals {
      background-color: transparent; padding: $gridunit 0; font-size: $base-font-size; line-height: 1.3;
      .row {
        margin: $gridunit-small 0 0;
        &.grandtotal {
          display: flex; font-weight: $bold; font-size: $font-size-larger;
          .value { font-weight: $bold; color: $brand-primary-800-wcag; }
        }
      }
    }
    .summary-list-item .discount { color: $brand-primary-800-wcag; }
  }

  .callout {
    background-color: transparent !important; border: 2px solid $brand-gray-200; border-radius: $base-radius; padding: $gridunit-small; line-height: 1.4; font-size: $font-size-small;
    &:before { display: none !important; }
    .sum { color: $brand-primary-800-wcag; }
    .callout-content { padding: 0; }
    .title {
      font-size: $font-size-medium;
      IMG { margin-right: $gridunit-small; max-width: 18px; border-radius: 2px; overflow: hidden; }
    }
  }
  .layout-overlay {
    .summary {
      padding: 0 $gridunit;
      + .callout { margin-top: $gridunit !important; }
    }
    .callout {
      margin: 0 $gridunit $gridunit !important;
      &-content { flex: 0 0 100%; }
    }
    .heading-with-buttons .heading svg { display: none; }
  }
}


