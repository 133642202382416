HTML {
  .button{
    &.size-small .icon {
      margin-left: 0;
      path { stroke: transparent; }
    }
    &.layout-default:before { border-radius: $gridunit*1.5; background-color: $brand-primary-800-wcag; }
  }
  * { letter-spacing: .05em !important; }
  .ebeauty-dialog-heading { z-index: 2; }
}
.frame-ebeauty {
  &__header {
    #main-header {
      @include desktop { margin-top: -1px; }
    }
    .logo {
      IMG { width: 100%; height: 100%; }
      @include desktop {
        width: 55px; height: 55px; min-width: 55px;
        margin-right: $gridunit; left: $gridunit; top: $gridunit/2; z-index: 2;
      }
      @include not-desktop {
        width: 70px; height: 70px; position: absolute; z-index: 2; pointer-events: all;
        left: 0; right: 0; top: $gridunit-large; margin: auto;
      }
    }
    &__primary {
      border-bottom: none; padding-bottom: 0;
      @include mobile {
        padding-bottom: 0; height: 0; pointer-events: none;
      }
      @include not-desktop { overflow: visible !important; position: relative; }
    }
    .search-desktop {
      @include desktop { margin-top: $gridunit-small; }
    }
    .userhub {
      @include desktop {
        flex: 0 1 100%; justify-content: flex-end; margin-top: $gridunit-small;
        .link-account A.toggler .label {
          SPAN {
            &.account-title { text-transform: none; letter-spacing: 0; font-weight: $bold; font-size: $font-size-small; }
            &.account-subtitle { display: none; }
          }
        }
      }
      .link-account .#{$ns}-transition-container A.button { border-radius: $base-radius-large; }
    }
    &__limiter { align-items: flex-start; }
    &__nav {
      &,
      * { font-family: 'apparat', Arial, Helvetica, sans-serif; }
      @include desktop {
        .site-menu {
          padding-bottom: $gridunit-small/2; max-width: 100%;
          &__wrapper { width: 100%; }
          NAV > UL {
            width: 100%; justify-content: space-between;
            @for $i from 1 through 20 {
              &:has(> LI:last-child:nth-child(#{$i})) {
                > LI {
                  > A,
                  .bp3-popover-target A{ font-size: 35px - (1.5* $i); }
                }
              }
            }
            @-moz-document url-prefix() {
              justify-content: center;
            }
            > LI {
              .bp3-popover-wrapper A,
              > A { font-size: $font-size-medium; }
            }
            @include desktop-wide {
              @-moz-document url-prefix() {
                > LI {
                  > A,
                  .bp3-popover-target A {
                    font-size: 22px;
                  }
                }
              }
            }
            @include desktop-very-wide {
              @-moz-document url-prefix() {
                > LI {
                  > A,
                  .bp3-popover-target A {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
      @include not-mobile {
        box-shadow: $shadow03;
      }
    }
    @media only screen and (min-width: 760px) and (max-width: 994px) {
      &__primary { padding-bottom: $gridunit-small; max-height: 0; }
      .frame-ebeauty__languages { z-index: 2; }
    }
    @include desktop-narrow {
      .site-menu NAV > UL {
        .#{$ns}-popover-wrapper A,
        > LI > A { padding: $gridunit $gridunit-small; }
      }
      .search-desktop { max-width: 250px; }
    }
    @include not-desktop {
      .logo { top: $gridunit-small*1.5; }
      &__limiter { position: relative; width: 100%; }
      .frame-ebeauty__languages { display: none; }
      &__nav {
        height: 100px;
        .site-menu {
          position: absolute; left: 0; top: 0;
          SPAN:not(.icon) { display: none; }
        }
        .search-mobile {
          position: absolute; left: 0; top: 45px; padding-left: 0 !important;
          SPAN:not(.icon) { display: none; }
        }
        .cart-mobile .mini-cart {
          position: absolute; right: 0; top: -5px; flex-direction: column; align-items: flex-end;
          .label,
          .delivery { display: none; }
          .amount { margin-top: $gridunit-small*1.5; }
        }
      }
    }
  }
  &__footer {
    .text-intent-danger { color: #FFB3AD; }
    @include mobile {
      .group:not(:first-child) { margin: 0; }
    }
  }
  &__page-header H1.has-background {
    padding: $gridunit-large*2 $gridunit $gridunit-large;
    text-transform: lowercase;
  }
  &__languages {
    @include not-desktop {
      display: block; margin-left: -10px; margin-top: 80px;
    }
    @include mobile {
      display: none;
    }
  }

  .banners.wrap {
    .banner {
      &.size-medium {
        A {
          @include tablet {
            padding-bottom: 37%;
          }
          @media only screen and (max-width: 495px) {
            padding-bottom: 39.87%;
          }
        }
      }
      &.size-small {
        A {
          padding-bottom: 55.7%; height: 0;
          IMG { max-width: none; }
        }
      }
    }
  }
}

.site-menu {
  NAV {
    > UL {
      .badge {
        svg path { stroke: $brand-campaign-1; }
      }
    }
  }
  &-mobile {
    @include not-desktop {
      .nested-menu__item {
        A { text-decoration: none; }
        &.secondary {
          background-color: $brand-gray-200; padding: $gridunit-small $gridunit-small 0;
          H3 { padding: 0 $gridunit-small; font-weight: $bold; font-size: $font-size-smaller; text-transform: uppercase; margin: $gridunit 0 $gridunit-small; color: $brand-primary-800; }
          A {
            text-transform: none; color: $color-text-dark; display: block; padding: $gridunit-small; font-size: $font-size-medium; font-weight: $bold;
            &.button { text-align: left; }
          }
          .mobile-languages {
            background-color: darken($brand-gray-200, 3%); justify-content: flex-start; margin: $gridunit $gridunit-small*-1 0; padding: $gridunit-small; display: flex;
            @include tablet { padding-bottom: 50px; }
            A { display: flex; flex-direction: column; align-items: center; font-size: $font-size-smaller; }
          }
        }
      }
    }
  }
}

.generic-callout {
  &.intent-promotion {
    color: $brand-primary-800; background: $brand-accent-4;
    A { color: $brand-primary-800; text-decoration: underline; }
  }
}

H1,
.frame-ebeauty__section__header H2,
.product-brand {
  font-family: "ivypresto-display", serif; font-weight: 100; letter-spacing: .05em; font-size: $font-size-largest;
  @include not-mobile {
    font-size: 30px;
  }
}

H1 {
  @include not-mobile { font-size: 40px; }
}

.layout-product H1 { font-family: 'apparat-light', sans-serif; }

.tabbed-content.style-minimal {
  .tabbed-content__tabs > LI > A {
    text-transform: none; font-size: $font-size-larger; font-family: "ivypresto-display", serif; font-weight: 100; letter-spacing: .05em;
    @include not-desktop { letter-spacing: 0; font-size: $font-size-medium-large; }
  }
}

.system-notification {
  @include mobile {
    font-size: $font-size-small;
  }
}

.link-cart .delivery { display: none; }
.cart-mobile {
  @include not-desktop {
    .mini-cart {
      flex-direction: row; flex-wrap: wrap; justify-content: flex-end; align-items: center; margin-top: $gridunit-small/2;
      .amount { display: block; }
      .icon {
        position: relative; margin: $gridunit-small 0 0; padding: 0 $gridunit-small;
        .products { right: 0 !important; top: 0; }
      }
      .delivery { flex: 0 0 100%; text-align: right; margin: $gridunit-small/2 0 0; }
    }
  }
}

.shortcuts {
  padding: $gridunit $gridunit-small 0;
  LI { flex: 1; min-width: 50px; font-size: 8px; margin: 0; }
}

HTML {
  .frame-checkout-nav {
    .logo img {
      height: 80px; width: 80px;
      @include not-desktop {
        height: 30px; width: 30px;
      }
    }
    .userhub .link-account {
      A.toggler {
        min-width: 0;
        &:before,
        &:after { display: none !important; }
        .icon { border-radius: 0; }
        .label {
          @include not-mobile {
            .account-title { font-size: $font-size-small; }
            .account-subtitle { display: none !important; }
          }
        }
      }
    }
  }
  &.global-frame-checkout .frame-checkout .summary .summary-totals .row.grandtotal {
    font-size: $font-size-medium-large !important;
    .value { font-size: inherit; }
  }
  .radio-list .radio-with-label.selected,
  .selected .selectable-item__inner { background-color: $brand-accent-4; border-color: $brand-accent-4; }
  .frame-checkout__sidebar-icon { display: none; }
  .banklinks { max-width: 300px; }
  .layout-sidebar-secondary {
    @include not-mobile {
      position: sticky; top: 180px;
    }

  }

}

.search__inner.open {
  &:before { border-style: none none solid none; }
}

@include not-mobile {
  .search-desktop .search__results {
    &.open:before { border-radius: 0; }
    border-radius: 0; padding-top: 5px;
  }
}

@include mobile {
  .search-mobile-content .search__results {
    pointer-events: none;
    &.open{
      pointer-events: all;
      &:before { border-radius: 0; border-width: 1px; border-style: solid; }
    }
    border-radius: 0;
  }
  .search__inner.open:before { display: none; }
}

.box-product {
  @include mobile {
    padding: $gridunit-small;
    &__actions {
      .button {
        font-size: $font-size-smaller;
      }
    }
  }
  @include mobile-small {
    padding: $gridunit-small $gridunit;
  }
}

.product-actions__quantity BUTTON {
  &:first-child { border-radius: 2em 0 0 2em; }
  &:last-child { border-radius: 0 2em 2em 0; }
}

.system-notification__close { border-radius: $base-radius-large; }

.cart-block.theme01 {
  background-color: transparent; padding: 0 0 $gridunit; border-bottom: 1px solid $brand-gray-350;
}

A.button.layout-link.intent-discrete { color: $brand-primary-800-wcag; }

.global-frame-andbeauty,
.global-frame-checkout {
  .button.layout-default { border-radius: 24px; }
}

#tw-chat-root {
  @include mobile { display: none; }
}
.tw-chat IFRAME { z-index: 19 !important; }

.frame-ebeauty .carousel {
  .control-pause { display: none !important; }
  .control-page {
    text-indent: -10000px; width: 8px; height: 8px; position: relative; margin: 0 6px; color: transparent; font-size: 0;
    &:before { content: ""; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 20px; height: 20px; }
  }
}

.promo-texts {
  display: flex; justify-content: center; margin: $gridunit 0 0; padding: $gridunit-large;
  @include tablet { padding: $gridunit-large 0; font-size: $font-size-medium-small; }
  @include mobile { display: none; }
  &__item {
    padding: 0 $gridunit-large; text-transform: uppercase; text-align: center; display: flex; flex-direction: column; justify-content: center; align-items: center; flex: 1; max-width: 350px;
    @include tablet { padding: 0 $gridunit; }
    &:not(:first-child) { border-left: 1px solid $color-black; }
    > *:first-child { margin-top: 0; }
  }
}

.frame-ebeauty .banklinks {
  &.layout-default {
    max-width: none; padding: 0 $gridunit-large;
    @include mobile {
      padding: 0 0 0 $gridunit;
    }
    .banklinks-item {
      @include not-mobile { flex: 0 0 50%; padding: $gridunit $gridunit 0 0; margin: 0; box-sizing: border-box; }
      @include mobile { flex: 0 0 100%; padding: $gridunit 0 0 0; margin: 0; box-sizing: border-box; }
    }
    BUTTON {
      border: 1px solid $brand-gray-350; position: relative; text-align: left; box-sizing: border-box; min-height: $gridunit-small+26px; border-radius: 3px; padding: $gridunit-small/2 $gridunit-small+77px $gridunit-small/2 $gridunit-small; width: 100%; height: auto;
      .banklinks {
        &-item {
          &-image { width: 77px; height: 26px; left: auto; right: $gridunit-small/2; top: 50%; transform: translate(0, -50%); }
          &-label { overflow: visible; line-height: 1.5; margin-top: 3px; font-size: $font-size-medium-small; font-weight: $bold; }
        }
      }
      &:hover {
        text-decoration: none; background-color: transparentize($brand-primary-200, .8);
      }
    }
  }
}

HTML .cta-block {
  &__text {
    @include not-mobile {
      flex: 0 1 100%;
    }
  }
  &__image {
    PICTURE { display: block; width: 100%; height: 100%; }
    @include not-mobile {
      flex: 0 0 335px;
    }
    @include mobile {
      max-height: none; flex: 0 0 auto; box-sizing: border-box; position: relative; padding-bottom: 55%;
      A { position: absolute; left: 0; top: 0; right: 0; bottom: 0; }
      IMG { height: 100%; }
    }
  }
}


@mixin banner-large { @media only screen and (min-width: 1080px) { @content; } }
@mixin banner-medium-large { @media only screen and (min-width: 1080px) and (max-width: 1439px) { @content; } }
@mixin banner-medium { @media only screen and (min-width: 575px) and (max-width: 1079px) { @content; } }
@mixin banner-small { @media only screen and (max-width: 574px) { @content; } }

