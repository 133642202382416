// Single product layout

.layout-product {
  &__row {
    &:not(:first-child) { margin-top: $gridunit-large; }
  }
  @include desktop {
    padding-bottom: $gridunit-large;
    &__row { display: flex; flex-direction: row; flex-wrap: nowrap; }
    &__image { flex: 0 1 50%; padding-right: 80px; font-size: 12px; line-height: 1.5; box-sizing: border-box; }
    &__content {
      flex: 0 1 50%;
    }
    &__meta {
      flex: 0 1 50%; max-width: 50%; padding-right: 80px; box-sizing: border-box;
      &:last-child { padding-right: 0; }
    }
    &__tabs {
      flex: 0 1 50%; max-width: 50%;
      &:first-child:last-child { flex: 0 0 100%; max-width: none; }
    }
    &__meta,
    &__tabs {
      H2:first-child { margin: 0; }
    }
  }

  @include desktop-narrow {
    &__row.wrap-narrow { flex-direction: column; }
  }

  H1 {
    font-size: $font-size-larger; margin: 0; font-weight: $bold;
    @include mobile {
      font-size: $font-size-large;
      .product-rating { padding: 0 3px; top: 0; }
    }
  }

  &__image {
    position: relative;
  }
}
