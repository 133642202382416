$color-text-dark: #252629;

$brand-primary-200: #e6caca;
$brand-primary-600: #ccafaf;
$brand-primary-800: #9a5f60;
$brand-primary-800-wcag: $brand-primary-800;
$brand-primary-900: #7b4142;

$brand-accent-wcag: $brand-primary-800-wcag;
$brand-accent-100: #dfc7bf;
$brand-accent-200: #c08f80;
$brand-accent-1: #a86f60; //#99B7CC;
$brand-accent-2: #d9bea9;
$brand-accent-2-200: #e5d1c1;
$brand-accent-2-100: #f2e8e0;

$brand-accent-3: #eee2d8;
$brand-accent-4: #ebe8e4;

$brand-gray-200: #f7f4f0;
$brand-gray-300: darken($brand-gray-200, 5%);
$brand-gray-800: $color-gray-dark;

$brand-campaign-1: #bb8f82;
$brand-campaign-1-tint: #e7cfc3;
$brand-campaign-2: #c3baa9;
$brand-campaign-2-tint: #dddec0;
$brand-campaign-3: #d6c0a9;
$brand-campaign-3-tint: #f1dcc7;
$brand-campaign-4: #8a8b7b;
$brand-campaign-4-tint: #dadbc2;
$brand-campaign-5: #c3d1e7;
$brand-campaign-5-tint: #e1e8f0;

$brand-badge01-background: $brand-primary-800-wcag;
$brand-badge01-text: $brand-primary-text;
$brand-badge02-background: $brand-gray-200;
$brand-badge02-text: $color-text-dark;
$brand-badge03-background: $brand-accent-1;
$brand-badge03-text: $color-text-dark;
$brand-badge04-background: $brand-primary-800-wcag;
$brand-badge04-text: $brand-primary-text;

$color-background-header-primary: $color-white;
$color-background-header-secondary: $color-background-header-primary;
$color-border-header: $color-gray-lighter;
$color-accent-product: #f2f9fc;

$color-background-languages-idle: $color-white;
$color-background-languages-hover: $color-gray-light;
$color-background-languages-active: $color-gray-medium-light;

$color-background-crossportal-hover: transparentize($brand-primary-200, 0.6);
$color-background-crossportal-active: transparentize($brand-primary-200, 0.6);
$color-system-focus: #0F7798;

$color-text-header-primary: $color-text-dark;
$color-text-header-secondary: $color-text-medium;
$color-text-header-highlighted: $brand-primary-800;
$color-header-accent: $brand-primary-600;

$color-background-mainmenu-level1-hover: $brand-accent-3;
$color-background-mainmenu-level1-active: $brand-accent-3;
$color-border-mainmenu-level1-active: $brand-primary-800;
$color-background-mainmenu-level1-selected: $brand-primary-800;
$color-background-mainmenu-level1-selected-hover: $brand-primary-900;
$color-background-mainmenu-level1-selected-active: $brand-primary-800-wcag;
$color-text-mainmenu-level1-selected: $color-text-light;

$color-background-mainmenu-level2-hover: $brand-accent-3;
$color-background-mainmenu-level2-active: $brand-accent-3;
$color-border-mainmenu-level2-active: $brand-primary-800;
$color-background-mainmenu-level2-selected: $brand-primary-800;
$color-background-mainmenu-level2-selected-hover: $brand-primary-900;
$color-background-mainmenu-level2-selected-active: $brand-primary-800-wcag;
$color-text-mainmenu-level2-selected: $color-text-light;

$shadow-active: 0 0 0 1px $color-border-mainmenu-level1-active;
$shadow-active2: 0 0 0 1px $color-border-mainmenu-level2-active;

$color-danger: $brand-primary-800-wcag;
$color-success: #8a8b7b;

$color-link: $brand-primary-800-wcag;

$color-system-success-800: darken($color-success, 5%);
$color-system-success-250: lighten(#acad9e, 5%);
$color-system-success-200: lighten($color-system-success-250, 15%);
$color-system-warn-200: #f2e8e0;
$color-system-warn-500: #e5d1c1;
$color-system-warn-800: #d9bea9;
$color-system-warn-1000: darken($color-system-warn-800, 10%);
$color-system-warn-650: darken($color-system-warn-800, 25%);
$color-system-danger-800: #cd9c80;
$color-system-danger-200: lighten($color-system-danger-800, 15%);
$color-system-danger-300: lighten($color-system-danger-800, 10%);
$color-system-info-200: #e4e8eb;
$color-system-info-1000: darken($color-system-info-200, 35%);
$color-system-info-250: darken($color-system-info-200, 5%);
$color-system-info-350: darken($color-system-info-200, 15%);

