@import "../variables";
@import "../mixins";

@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";

@import "~@blueprintjs/core/src/common/variables";

$date-size: 30px;

.DayPicker {
	display: inline-block;
	&:focus { outline: none; }

	&-wrapper {
		position: relative;
		&:focus { outline: none; }
	}
	&-NavBar {
		position: absolute; left: 0; right: 0; z-index: 1;
		.DayPicker-NavButton {
			position: absolute; top: 0; width: $date-size; height: $date-size; background: transparent; border: none; padding: 0;
			&[disabled] { opacity: 0.2; }
			path { fill: currentColor; }
		}
		.DayPicker-NavButton--prev { left: 0; }
		.DayPicker-NavButton--next { right: 0; }
	}
	&-Months { display: flex; flex-direction: row; }
	&-Month {
		max-width: 240px; position: relative;
		.DayPicker-Caption {
			padding: 0 $date-size; text-align: center; margin-bottom: 10px; min-height: $date-size; line-height: $date-size; pointer-events: none;
			text-transform: uppercase; letter-spacing: 1px; font-weight: $bold; color: $color-text-dark;
			SELECT { text-overflow: ellipsis; text-transform: capitalize; color: inherit; display: inline; appearance: none; padding: 0 10px; margin: 0 5px; background: transparent; border: none; font-weight: $bold; height: $date-size; line-height: $date-size; }
			.#{$ns}-divider { display: none; }
			.#{$ns}-icon { display: none; }
			.#{$ns}-html-select { display: inline; }
			.#{$ns}-datepicker-caption {
				display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: stretch;
				> * { flex: 1 0 100%; }
				SELECT { width: 100%; }
			}
		}
		.DayPicker-Weekdays {}
		.DayPicker-WeekdaysRow {
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;
			.DayPicker-Weekday { color: rgba($color01, 0.5); font-size: 12px; width: $date-size; text-align: center; text-transform: uppercase; letter-spacing: 1px; }
		}
		.DayPicker-Body { min-height: ($date-size * 6) + 12; }
		.DayPicker-Week {
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; padding: 0;
			.DayPicker-Day {
				color: $color-link; margin: 1px;
				flex: 0 0 auto; width: $date-size; height: $date-size; line-height: $date-size; box-sizing: border-box; overflow: hidden; text-align: center; outline: none; border-radius: $base-radius; cursor: default;
				&:hover { background-color: $color-link; color: $color-white; }
			}
			.DayPicker-Day--outside { opacity: 0.3; }
			.DayPicker-Day--selected { color: $color-white; background-color: $color-active !important; }
			.DayPicker-Day--selected-range { color: $color-white; background-color: rgba($color05, 0.3); }
			.DayPicker-Day--disabled { color: rgba($color-text-dark, 0.3) !important; background-color: transparent !important; }
		}
		ABBR { text-decoration: none; }
	}
}
