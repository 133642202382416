.rx-chat {
  position: fixed; bottom: 0; right: 0; z-index: 40;
  @include mobile {
    bottom: 0; right: 0; left: 0;
  }
  &-inline { max-width: 680px; margin-top: $gridunit; }
  &__toggle {
    margin: 0 $gridunit $gridunit 0; border: none; background-color: $color-white; box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15); border-radius: $base-radius; padding: 0 $gridunit 0 0; font-weight: $bold;
    display: flex; align-items: center; overflow: hidden; cursor: pointer;
    @include mobile { width: 100%; border-radius: 0; text-align: center; margin: 0;}
    SPAN { flex: 0 1 100%; }
    &-icon {
      flex: 0 0 auto !important; padding: $gridunit-small*1.5 $gridunit; margin-right: $gridunit-small; background-color: $brand-primary-800-wcag;
      svg {
        path { fill: $color-text-light; }
      }
    }
    &:focus { box-shadow: $shadow-focus2; }
  }
  &__frame {
    max-width: 500px; width: 100vw; height: 100vh; max-height: 750px; background-color: $color-white; display: flex; flex-direction: column;
    &.minimized { height: auto; }
    .wrapper1 { width: 100% !important; box-sizing: border-box !important; }
    @include mobile {
      height: 100vh; max-height: none; width: 100vw;
    }
  }
  &__header {
    flex: 0 0 auto; background-color: $brand-primary-800-wcag; color: $color-text-light; padding: $gridunit-small; display: flex; justify-content: space-between; align-items: flex-end;
    &-logo {
      IMG { width: $gridunit-large; height: $gridunit-large; }
    }
    &-actions {display: flex; }
    BUTTON {
      margin-left: $gridunit-small; border: none; border-radius: $base-radius; display: flex; justify-content: center; align-items: center; padding: 0; width: $gridunit-large; height: $gridunit-large; background-color: transparent; cursor: pointer;
      &:hover { background-color: rgba(0,0,0,0.1); }
      &:focus { box-shadow: $shadow-focus2; }
      svg {
        path { fill: $color-white; }
      }
    }
  }
  &__container {
    flex: 0 1 100%; border: 1px solid $color-gray-light; overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $brand-gray-200;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $brand-accent-100; border-radius: $base-radius;
    }
    .minimized & { display: none; }
    .contact-form { margin: $gridunit-small; }
  }
}

.chat-open {
  @include  mobile { height: 100%; overflow: hidden; }
}
