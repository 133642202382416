// Forms

FORM.margt { margin-top: $gridunit; }

.form-container {
	display: block; @include defaultMargin(); padding: $gridunit; border-radius: $base-radius; background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16);
	H1, H2, H3, H4, H5, H6 {
		margin-top: 0;
	}
	HR { border: 1px rgba(0,0,0,0.05); border-style: solid none none; margin: $gridunit-large 0 0; background: none; }
}

.form-row {
	&-instructions {
		font-size: $font-size-small; margin: $gridunit-small/2 0 0;
		&.text-intent-danger{ font-weight: $bold; }
		IMG { position: relative; top: .2em; }
	}
	&-item {
		&.compact { margin-top: $gridunit-small; }
	}
}

.form-list {
	padding: 0 0 $gridunit-small/2 3px; margin: 0 $gridunit / -2 -3px; line-height: $gridunit; overflow: hidden; display: flex; flex-direction: row; flex-wrap: wrap; max-width: 480px;
	&.margt { margin-top: $gridunit; }
	&.wide { max-width: 680px; }
	&.full { max-width: 100%; }
	&.compact > LI:not(:first-child) { margin-top: $gridunit-small; }
	&.separated { padding: $gridunit*2 0; }
	> LI {
		flex: 1 1 100%; padding: 0 $gridunit / 2; margin: $gridunit-large 0 0 0; position: relative; box-sizing: border-box;
		&:first-child:not(.split) { margin: 0; }
		&.split { flex: 0 1 50%; min-width: 220px; }
		&.separated { border-top: 1px solid $brand-gray-300; padding-top: $gridunit-large; }
		&.error {
			color: $color-danger;
			INPUT, SELECT, TEXTAREA { border-color: $color-danger; color: $color-danger; }
		}
	}

	$h: 40px;

	.form-row-label {
		display: block; padding: 0 0 5px 0; line-height: inherit; font-weight: $medium-bold;
	}

	INPUT, SELECT, TEXTAREA {
		appearance: none; outline: none; font-size: $base-font-size;
		margin: 0; width: 100%; border: 1px solid $brand-gray-400; border-radius: $base-radius; padding: $gridunit-small $gridunit-small*1.5;
		&:hover,
		&:active { border-color: $brand-gray-placeholder; }
		&:focus { box-shadow: $shadow-focus; }
		&.error { border-color: $color-danger; color: $color-danger; }
	}

	INPUT {
		&:not(:last-child) { padding-right: 50px; }
		&[type="checkbox"] {
			position: absolute; right: 100%; bottom: 100%;
			+ LABEL {
				position: relative; padding: 0 0 0 $gridunit + 10px; display: block;
				&:before, &:after { transition: all $uispeed $uieasing 0ms; }
				&:before { content: ""; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: 0; background-color: rgba($color-black, 0.05); border-radius: $base-radius; }
				&:after { content: ""; width: 10px; height: 10px; position: absolute; left: 5px; top: 5px; background-color: $color-link; border-radius: 3px; opacity: 0; }
			}
			&:checked + LABEL {
				&:after { opacity: 1; }
			}
			&:focus + LABEL {
				&:before { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
			}
		}
	}

	TEXTAREA { line-height: $gridunit; padding: 10px; height: 150px; }
	.content-card & + .buttons { margin-left: $gridunit-small*-1; }
}

.form-toggler {
	position: relative; overflow: hidden; margin: $gridunit 0 0 0; line-height: $gridunit;
	>  INPUT[type="checkbox"] {
		position: absolute; right: 100%; bottom: 100%;
		+ LABEL {
			position: relative; padding: 0 0 0 $gridunit + 10px; display: block; line-height: inherit;
			&:before, &:after { transition: all $uispeed $uieasing 0ms; }
			&:before { content: ""; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: 0; background-color: rgba($color-black, 0.05); border-radius: $base-radius; }
			&:after { content: ""; width: 10px; height: 10px; position: absolute; left: 5px; top: 5px; background-color: $color-link; border-radius: 3px; opacity: 0; }
		}
		&:checked + LABEL {
			&:after { opacity: 1; }
			& ~ * { display: block; }
		}
		&:focus + LABEL {
			&:before { box-shadow: inset 0 0 0 1px rgba($color-black, 0.1) }
		}
	}

	&__content { display: none; }
}

.form-buttons {
	$h: 40px;
	display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start; justify-content: flex-start; margin: 10px -10px 0 0;
	> LI { flex: 0 0 auto; margin: 10px 10px 0 0; }
	&__button {
		display: block; padding: 0 $gridunit-large; line-height: $h; height: $h; background-color: $color-white; box-shadow: inset 0 0 0 1px $color-link; white-space: nowrap; border: none; border-radius: $h / 2; cursor: pointer; box-sizing: border-box; text-align: center; outline: none;
		&.intent-primary {
			background-color: $color-link; color: $color-white; font-weight: $bold;
			&:hover { background-color: $color-link-hover; text-decoration: none; }
		}
		&:focus { box-shadow: inset 0 0 0 1px $color-link, 0 0 0 3px rgba($color-link, 0.2); }

		&[disabled],
		&.disabled { opacity: 0.5; pointer-events: none; }
	}
	&__social {
		display: block; padding: 0 $gridunit 0 40px; line-height: $h; height: $h; background-color: $color-white; box-shadow: inset 0 0 0 1px $color-link; white-space: nowrap; border: none; border-radius: $h / 2; cursor: pointer; box-sizing: border-box; text-align: center; outline: none;
		&.intent-primary {
			background-color: $color-link; color: $color-white; font-weight: $bold;
			&:hover { background-color: $color-link-hover; text-decoration: none; }
		}
		&:focus { box-shadow: inset 0 0 0 1px $color-link, 0 0 0 3px rgba($color-link, 0.2); }
		&.facebook { background: rgb(24, 119, 242) url(../img/icon-facebook.svg) 10px 10px no-repeat; background-size: 20px 20px; color: $color-white; }
		&.google { background: $color-white url(../img/icon-google.svg) 10px 10px no-repeat; background-size: 20px 20px; }
	}

	@include mobile {
		flex-direction: column; align-items: stretch; justify-content: stretch;
		&__button { width: 100%; }
	}
}

.form-control {
	border: 1px solid $brand-gray-400; border-radius: $base-radius; padding: $gridunit-small $gridunit-small*1.5; background-color: $color-white; line-height: 1.2;
	&:hover,
	&:active { border-color: $brand-gray-placeholder; }
	&:focus { box-shadow: $shadow-focus; }
	&-phone {
		border: 1px solid $brand-gray-400; border-radius: $base-radius; display: flex; padding: $gridunit-small/2;
		SELECT { flex: 0 0 80px; border: none; background-color: transparent; border-right: 1px solid $brand-gray-300; padding: $gridunit-small/2 30px $gridunit-small/2 $gridunit-small; }
		INPUT { flex: 0 1 100%; padding: $gridunit-small/2 $gridunit-small*1.5; border: none; position: relative; }
		.control-select-inner,
		.control-input { box-shadow: none; }
		.control-select-inner {border-right: 1px solid $brand-gray-300; }
		.control-select { flex: 0 0 auto; }
		.control-input { flex: 1 1 auto; }
	}
	&-url.control-input {
		margin: $gridunit-small*1.5 $gridunit-small/2 0 0;
		INPUT {
			padding: $gridunit-small/2 $gridunit-small !important;
			&.disabled,
			&:disabled,
			&[readonly],
			&.readonly { background-color: transparent; pointer-events: none; }
		}
	}
	&.disabled,
	&:disabled,
	&[readonly],
	&.readonly { background-color: $brand-gray-350; pointer-events: none; }
	&-password {
		border: 1px solid $brand-gray-400; border-radius: $base-radius; display: flex; padding: $gridunit-small/2;
		BUTTON {
			flex: 0 0 40px; cursor: pointer; border-radius: $base-radius; text-indent: -10000px; border: none; background-color: transparent; padding: $gridunit-small/2 30px $gridunit-small/2 $gridunit-small; position: relative;
			IMG { position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; width: 18px; height: 18px; }
			&:hover {background: $brand-gray-200; }
			&:active { background: $brand-gray-300; }
			&:focus {box-shadow: $shadow-focus; }
		}
		INPUT { flex: 0 1 100%; padding: $gridunit-small/2 $gridunit-small*1.5; border: none; }
	}
	&-checkbox-group {
		border: none; margin: 0; padding: 0;
		LEGEND { font-weight: $bold; margin: 0; }
		LABEL { margin: $gridunit 0 0; }
		INPUT {
			&[type="radio"],
			&[type="checkbox"] {
				+ LABEL { margin: 0; }
			}
		}
		UL.checkboxes {
			margin: $gridunit 0 0;
			&.inline { margin: 0; }
		}
	}
	&-group {
		border: none; padding: 0; margin: $gridunit*2 0 0;
		&:first-child { margin: 0; }
		LEGEND {
			margin: 0; padding: 0; font-weight: $bold; font-size: $font-size-larger04;
			.form-list & { font-size: $base-font-size; }
			H2 { font-weight: inherit; font-size: inherit; }
		}
	}
	.#{$ns}-popover-content & { width: 100%; }
}

.checkboxes {
	&.inline {
		display: flex; flex-wrap: wrap;
	}
	&.margt { margin-top: $gridunit; }
	&.separated { padding: $gridunit-large 0; }
	&:not(.inline) > LI:first-child { margin: $gridunit-small $gridunit-small 0 0; }
	&.elevated {
		> LI {
			position: relative; background-color: $color-white; border-radius: $base-radius; padding: $gridunit $gridunit-small $gridunit-small; flex-wrap: wrap; border: 1px solid $brand-gray-300;
			INPUT:checked + LABEL + *:before { content: ""; position: absolute; left: 0; top: 0; right: 0; bottom: 0; box-shadow: 0 0 0 1px $brand-primary-800; border-radius: $base-radius; }
		}
	}
	&.vertical {
		> LI {
			&,
			&:first-child { margin: $gridunit-small 0 0; }
		}
	}
	&.theme01 {
		background-color: $brand-accent-100; border-radius: $base-radius; padding: $gridunit; margin: $gridunit 0 0;
		> LI {
			border-bottom: 1px solid $color-system-info-350; padding-bottom: $gridunit;
			&:last-child { border-bottom: none; }
			INPUT {
				&[type="radio"],
				&[type="checkbox"] {
					+ LABEL {
						font-weight: $bold; padding-left: $gridunit-small*3;
						&:before { left: 0; }
						&:after { left: 5px; }
					}
				}
			}
		}
	}
	&__prefix { padding: $gridunit-small/4 $gridunit 0 0; }
	&__suffix { padding: $gridunit-small/4 0 0 $gridunit; }
	&__description,
	&__meta {
		flex: 0 0 100%;
	}
	&__meta {
		> P { font-size: $font-size-small; }
	}
	&.in2cols {
		max-width: 680px; margin: 0 0 0 $gridunit-small*-1; display: flex; flex-wrap: wrap;
		> LI {
			flex: 1 1 calc(50% - #{$gridunit-small*2}); min-width: 240px; box-sizing: border-box;
			&,
			&:first-child { margin: $gridunit $gridunit-small; }
		}
	}
	&.in3cols {
		max-width: 100%; margin: $gridunit 0 0; gap: $gridunit-small; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: $gridunit; grid-row-gap: $gridunit;
		@include tablet { grid-template-columns: 1fr 1fr; }
		@include mobile { grid-template-columns: 1fr; }
		> LI {
			box-sizing: border-box;
		}
	}
	> LI {
		margin: $gridunit $gridunit-small*1.5 0 0; display: flex; position: relative; min-height: $gridunit; flex-wrap: wrap;
		.center & { justify-content: center; }
		INPUT {
			&[type="radio"],
			&[type="checkbox"] {
				position: absolute; left: 0; top: 0; opacity: 0;
				+ LABEL {
					position: relative; padding: $gridunit-small/2 0 0 $gridunit-small*3; font-weight: $medium-bold; line-height: 1.25;
					&:before { content: ""; box-sizing: border-box; border-radius: $base-radius; background: $color-white; border: 1px solid $brand-gray-400; width: $gridunit; height: $gridunit; position: absolute; left: 0; top: .3em; }
					&:after { content: ""; box-sizing: border-box; width: 6px; border-radius: 0; height: 6px; position: absolute; left: 5px; top: .3em; margin-top: 5px; }
				}
				&:checked + LABEL:after { background-color: $brand-primary-800; }
			}
			&[type="checkbox"]:checked + LABEL:after {
				border: 3px $brand-primary-800; border-style: none solid solid none; background-color: transparent; box-sizing: content-box; margin-top: 1px;
				height: 8px; width: 4px; transform: rotate(45deg);
			}
			&[type="radio"] + LABEL {
				&:before,
				&:after { border-radius: 50%; }
			}
		}
	}
	.radio-with-label,
	.checkbox-with-label {
		padding: 0;
		.control-radio,
		.control-checkbox,
		&-label-wrapper { margin-top: 0; }
		&-label-wrapper { padding-left: $gridunit-small; }
		&-meta .content {
			font-weight: normal;
			> *:first-child { margin: 0; }
		}
	}
}

.checkboxes02 {
	display: flex; flex-wrap: wrap; margin: $gridunit $gridunit-small/-2 0;
	> LI {
		margin: $gridunit-small 0 0 0; box-sizing: border-box; padding: 0 $gridunit-small/2;
		@include not-desktop { flex: 0 0 auto; }
	}
	&.in2cols {
		@include desktop {
			> LI { flex: 0 0 50%; }
		}
	}
	LABEL {
		display: block; position: relative; cursor: pointer;
		&:hover {
			INPUT {
				&[type="radio"],
				&[type="checkbox"] {
					+ SPAN { background-color: $brand-accent-100; }
				}
			}
		}
	}
	INPUT {
		&[type="radio"],
		&[type="checkbox"] {
			position: absolute; left: 0; top: 0; opacity: 0;
			+ SPAN {
				display: block; border: 1px solid $brand-gray-400; border-radius: $base-radius; padding: $gridunit-small;
				IMG { margin-right: $gridunit-small; }
			}
			&:focus + SPAN { box-shadow: $shadow-focus2; background-color: $brand-accent-100; }
			&:checked + SPAN { border-color: $brand-accent-200; background-color: $brand-accent-200; }
		}
	}
}

.otc-form {
	&-group {
		margin: $gridunit-small 0 0; background-color: $brand-accent-100; padding: $gridunit; border-radius: $base-radius;
		&-heading {
			display: flex; justify-content: space-between;
			H3 { margin: $gridunit-small $gridunit-small 0 0; font-size: $font-size-medium; font-weight: $bold; flex: 0 1 100%;  }
		}
	}
	.form-list {
		max-width: none;
		> LI { max-width: 100%; }
		&:not(:first-child) { margin-top: $gridunit; }
	}
}

.contact-form {
	.img-left {float: left; margin-right: $gridunit; }
	.opening-time + .opening-time { display: inline-block; margin-left: $gridunit*3; }
}

.#{$ns}-slider {
	margin: $gridunit $gridunit-small 0; height: 30px;
	&-handle {
		background-color: $brand-primary-800;
		&:hover,
		&:active { background-color: darken($brand-primary-800, 10%); }
		&:focus { box-shadow: $shadow-focus2; }
		.range-slider & .#{$ns}-slider-label { display: none; }
	}
	&-track { height: 6px; }
	&-progress {
		background-color: $brand-gray-300; border-radius: 4px;
		.#{$ns}-intent-primary { background-color: $color-text-dark; }
	}
	&-label {
		display: none;
	}
}

.password-strength {
	background-color: $brand-gray-300; border-radius: $base-radius; position: relative; padding: $gridunit; overflow: hidden;
	&:before { content: ""; position: absolute; bottom: 0; left: 0; height: $gridunit-small/2; }
	&.status01:before { background-color: $color-danger; right: 66.666%; }
	&.status02:before { background-color: $color-warning; right: 33.333%; }
	&.status03:before { background-color: $color-success; right: 0; }
}
.frame-ebeauty,
.ebeauty-dialog {
	.radio-with-label .control-radio { margin-top: $gridunit-large/2; }
	.checkboxes {
		.checkbox-with-label .control-checkbox,
		.checkbox-with-label-label-wrapper,
		.radio-with-label .control-radio,
		.radio-with-label-label-wrapper { margin-top: 0; }
		.checkbox-with-label,
		.radio-with-label { min-height: 0; }
	}
	.control-checkbox,
	.control-radio {
		width: $gridunit; height: $gridunit;
		&-overflow {
			overflow: visible;
			INPUT:focus + SPAN { box-shadow: $shadow-focus2; }
		}
	}
	.control-radio {
		&-radio {
			border-color: #bcbcbc; background-color: $color-white;
			&.active {
				border-color: #bcbcbc !important; background-color: $color-white !important;
			}
			&:before { background-color: $brand-primary-800-wcag; width: 6px; height: 6px; }
		}
		&-overflow {
			INPUT:checked + SPAN {}
		}
	}
	.control-checkbox {
		&-checkbox {
			border-color: #bcbcbc; background-color: $color-white;
			&.active {
				border-color: #bcbcbc !important; background-color: $color-white !important;
			}
			SPAN {
				border-color: $brand-primary-800-wcag;
				border-width: 3px !important;
				border-style: none solid solid none;
				background-color: transparent;
				box-sizing: content-box;
				margin-top: -1px;
				height: 8px;
				width: 4px;
				transform: translate(-50%, -50%) rotate(45deg)
			}
		}
		&-overflow {
			INPUT:checked + SPAN {}
		}
	}
	.control-datepicker {
		INPUT { border: none; margin-right: $gridunit; }
		.control-input-addon { padding-left: $gridunit-small*1.5; padding-right: $gridunit-small*1.5; }
	}
}

.#{$ns}-datepicker {
	.DayPicker-Month .DayPicker-Caption {
		pointer-events: all;
		SELECT {
			text-transform: uppercase; padding: 0 16px 0 $gridunit / 2; margin: 0;
			background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' width='24' viewBox='0 0 11 6' xmlns='http://www.w3.org/2000/svg'><path d='M5.5,6C5.4,6,5.2,6,5.1,5.9l-5-5C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0l4.6,4.6l4.6-4.6c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7l-5,5C5.8,6,5.6,6,5.5,6z'/></svg>");
			background-repeat: no-repeat;
			background-position-x: calc(100% - 5px);
			background-position-y: .9em;
			background-size: 11px 6px;
			box-shadow: inset 0 0 0 1px $brand-gray-300; border-radius: $base-radius;
			OPTION { text-transform: none; font-weight: $normal; }
		}
		.#{$ns}-html-select + .#{$ns}-html-select { margin: $gridunit / 4 0 0 0; }
	}
}

.frame-ebeauty,
.ebeauty-dialog,
.global-frame-checkout {
	.control-input {
		&-input { height: auto; line-height: 1.5; padding: $gridunit-small $gridunit; }
	}
	.control-select {
		&.disabled .control-select-inner {
			background-color: $brand-gray-350; pointer-events: none; box-shadow: inset 0 0 0 1px rgba(51, 51, 51, 0.23);
		}
	}
	.control-autocomplete-inner {
		.control-input {
			&.focus { box-shadow: $shadow-focus !important; }
			&-input {
				border: none;
				&:focus { box-shadow: none; }
			}
		}
	}
	.control-select-popover .#{$ns}-popover-content {
		overflow-y: unset;
		-ms-overflow-style: unset;
		-webkit-overflow-scrolling: unset;
		scroll-behavior: unset;
		display: flex; flex-direction: column;
		.#{$ns}-menu {
			flex: 1; @include overflow; @include scrollbars
		}
	}
}
.control-input {
	INPUT {
		&.disabled,
		&:disabled,
		&[readonly],
		&.readonly { background-color: $brand-gray-350; pointer-events: none; }
	}
}

.form-control-stars INPUT:first-child {
	&:checked + LABEL { background-color: $brand-primary-800 !important; }
	+ LABEL { border-color: $brand-primary-800 !important; }
}

.newsletter-form {
	max-width: 800px; margin: 0 auto; padding-bottom: $gridunit;
	.form-row-label { display: none; }
	.form-list {
		> LI:not(:first-child) { margin-top: $gridunit-small; }
	}
	.checkbox-with-label-label { font-weight: $normal; font-size: $font-size-small; }
	@include not-mobile {
		&,
		FORM { display: flex; justify-content: center; }
		.form-list { margin-right: $gridunit-small; }
		.buttons { margin-top: 0 !important; }
	}
}

.form-row-inline {
	display: flex; position: relative;
	&:not(:first-child) { margin-top: $gridunit-small; }
	.button { border: none !important; margin: 0 !important; box-shadow: none; background-color: transparent !important; position: absolute; right: 0; top: 0; bottom: 0; }
}


